<template>
  <v-container fluid>
    <StandarPageWithList
      title="Positions Organization"
      :activeNo="activePositions"
      :empty="!storePositions.length"
    >
      <template #action>
        <Button
          @onClick="showAddPositionDialog = true"
          :normal="'normal-active'"
          class="add-position-btn"
          label="Add Position"
          :icon="'add-icon.svg'"
        ></Button>
      </template>
      <template #empty-content>
        <div class="d-flex flex-column align-center">
          <h3 class="mb-2">No positions found</h3>
          <p class="text-center mb-8">
            You need some positions in your <br />
            organization
          </p>
          <Button
            class="mb-6"
            :plusIcon="'add-icon.svg'"
            :general="true"
            :label="'Add Position'"
            @onClick="showAddPositionDialog = true"
          ></Button>
        </div>
      </template>
      <div class="px-5 mb-5">
        <SearchBar
          @onSearch="onFilter"
          type="button"
          :label="appliedFilters.length > 0 ? 'More Filters' : 'Filters'"
          :length="appliedFilters.length"
          :chipText="appliedFilters"
          ghostText="Search"
          :add="false"
          @onClick="showFilterDialog = true"
          @removeFilter="removeFilter"
          :valueSearch="filterText"
        ></SearchBar>
      </div>
      <DataTable
        ref="dataTableList"
        :pagination="paginationModel"
        :headers="headers"
        :data="filteredPositions"
        @onRequest="requestData"
        :useStatusRowColoring="true"
      >
        <template #no-data> </template>
        <template #position="{ row }">
          <div
            class="my-7 datatable-text-content"
            :data-position-id="row?.position?.data?.id"
          >
            <PositionCard
              :count="row.position.data.totalAssignment"
              :code="row.position.data.code + ''"
              :name="row.position.data.name"
              :occupied="'not'"
              :value="row.position.data.capacity"
              @input="onChangeCapacity($event, row)"
              @openPopup="openDetail(row)"
              :clickPopup="true"
            />
          </div>
        </template>
        <template #status="{ row }">
          <StatusChip
            :text="row.position.data.status"
            :textColor="statusColor(row.position.data.status)"
            :borderColor="statusColor(row.position.data.status)"
            :backgroundColor="statusColor(row.position.data.status)"
            :dotColor="statusColor(row.position.data.status)"
          />
        </template>
        <template #actions="{ row }">
          <DropDownMenu
            @onClick="handleDropdownClick($event, row)"
            :is-open="false"
            :dropdown-items="getOrgObjectDropdownItems(row.position.data)"
          >
            <template #activator>
              <div class="icon-container">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </DropDownMenu>
        </template>
      </DataTable>
    </StandarPageWithList>
    <NoRecordFound
      v-if="filteredPositions.length === 0"
      :title="'Positions'"
      :message="'Positions'"
      :btnTitle="'Position'"
      @onAction="showAddPositionDialog = true"
      class="no-record-found"
    />
    <AddOrgObject
      :show="showAddPositionDialog"
      @onAdd="savePosition"
      OrgObjectType="Position"
      ghostText="+ Add"
      description="You are about to add a new"
      type="add"
      :button-label="$loader.isAppLoading ? 'Saving' : 'Save'"
      @close="showAddPositionDialog = false"
    />
    <ExOrgObjectActions
      v-model="showActivatePositionDialog"
      objectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="activatePosition"
    />
    <ExDeleteOrgObject
      v-model="showDeletePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="deletePosition"
    />
    <RenameOrgObject
      v-if="showRenamePositionDialog"
      :openDialog="showRenamePositionDialog"
      :name="currentPosition?.name || ''"
      OrgObjectType="Position"
      :placeholder="currentPosition?.name || ''"
      @onAction="renamePosition($event)"
      @onClose="showRenamePositionDialog = false"
    />
    <ExPositionModal
      :dialog="showPositionRoleDialog"
      :modalHeaderState="modalHeaderState"
      @closeModal="closeRolePopup"
      @onButtonAction="openRoleToPosition"
      :items="menuItems"
      :tableData="paginatedAttachedRoles"
      :pagination="paginationRoleModel"
      @onRequest="requestRoleData"
      @onSearchMode="searchMode = $event"
    />
    <AttachedRole
      v-model="showRoleToPosition"
      OrgObjectType="Role to Position"
      @onSave="attachedRole"
      :items="items"
      @item-selected="handleItemSelected"
      @add-item-selected="addHandleItemSelected"
      :parent-name="currentPosition?.name || ''"
    />
    <SearchModal
      :dialog="showFilterDialog"
      @back="showFilterDialog = false"
      @checkedItemsChanged="filterEmployees"
      :clearSelection="clearSelection"
      filterType="Positions"
      :filterGroups="filterGroups"
      :statusOptions="statusOptions"
      :modalWidth="620"
      :defaultSelected="defaultSelected"
    />
    <ExCloneOrgObject
      v-model="showClonedPositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="clonePosition"
    />
    <ExDeActivatePopup
      v-model="showDeActivatePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="deActivatePosition"
    />
    <ReActivatePosition
      v-model="showReActivatePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="reActivatePosition"
    />
    <RetirePositionPopup
      v-model="showRetirePositionDialog"
      OrgObjectType="Position"
      :name="currentPosition?.name || ''"
      @onAction="retirePosition"
    />
  </v-container>
</template>
<script>
import StandarPageWithList from "@components/common/PageHeaderWithButton.vue";
import DataTable from "@components/DataTable/DataTable.vue";
import SearchBar from "@components/SearchBar/SearchBar.vue";
import StatusChip from "@components/organizations/common/StatusChip.vue";
import Button from "@components/buttons/Button.vue";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import AddOrgObject from "@components/AddOrgObject/AddOrgObject.vue";
import PositionCard from "@components/organizations/positions/PositionCard.vue";
import ExOrgObjectActions from "@components/common/ExOrgObjectActions.vue";
import ExDeleteOrgObject from "@components/DeleteOrgObject/ExDeleteOrgObject.vue";
import RenameOrgObject from "@components/RenameOrgObject/RenameOrgObject.vue";
import RetirePositionPopup from "@components/RetirePositionPopup/RetirePositionPopup.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import {
  createPosition,
  updatePosition,
  deletePosition,
  getListOfRoles,
  createRole,
  attachRoleToPosition,
  getListofResponsibility,
  deActivatePosition,
  reActivatePosition,
  retirePosition,
} from "@/api";
import ExPositionModal from "@components/PositionModal/ExPositionModal.vue";
import AttachedRole from "@components/RolesCatalog/AttachedRole.vue";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import ExCloneOrgObject from "@components/CloneOrgObject/ExCloneOrgObject.vue";
import { mapState } from "vuex";
import ExDeActivatePopup from "@components/DeActivatePosition/DeActivatePositionPopup.vue";
import ReActivatePosition from "@components/ReActivatePosition/ReActivatePositionPopup.vue";
export default {
  name: "PositionView",
  components: {
    SearchBar,
    DataTable,
    StatusChip,
    StandarPageWithList,
    Button,
    DropDownMenu,
    AddOrgObject,
    PositionCard,
    ExOrgObjectActions,
    ExDeleteOrgObject,
    RenameOrgObject,
    ExPositionModal,
    AttachedRole,
    SearchModal,
    ExCloneOrgObject,
    ExDeActivatePopup,
    ReActivatePosition,
    RetirePositionPopup,
    NoRecordFound,
  },
  mixins: [dropdownMixin],
  data() {
    return {
      storeFilterKey: "organizationPositions",
      showAddPositionDialog: false,
      showActivatePositionDialog: false,
      showDeletePositionDialog: false,
      showRenamePositionDialog: false,
      showPositionRoleDialog: false,
      showRoleToPosition: false,
      showClonedPositionDialog: false,
      showDeActivatePositionDialog: false,
      showReActivatePositionDialog: false,
      showRetirePositionDialog: false,
      filterText: "",
      accountID: "",
      positions: [],
      currentPosition: null,
      paginationModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },

      dataSourceRoles: [],
      modalHeaderState: null,
      items: [],
      attachmentId: null,
      searchMode: false,
      menuItems: [
        { title: "Roles attached", count: 0 },
        { title: "Assignments", count: 0 },
        { title: "Description", count: 0 },
        { title: "Chatter", count: 0 },
      ],
      matchedData: [],
      paginationRoleModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      showFilterDialog: false,
      clearSelection: "",
      appliedFilters: [],
      defaultSelected: [],
      filterGroups: [
        {
          label: "Occupied",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Occupied", value: "occupied" },
            { label: "Not Occupied", value: "not_occupied" },
          ],
        },
        {
          label: "Capacity",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "1 FTE", value: "cap_1_fte" },
            { label: "0.5 FTE", value: "cap_05_fte" },
          ],
        },
        {
          label: "Roles",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Has Roles", value: "has_roles" },
            { label: "Does not Have Roles", value: "not_have_roles" },
          ],
        },
      ],
      statusOptions: ["draft", "active", "retired", "inactive"],
    };
  },
  computed: {
    ...mapState("positionStore", {
      storePositions: (state) => state.positions,
    }),
    activePositions() {
      return this.filteredPositions.filter(
        (el) => el.position.data.status == "active"
      ).length;
    },
    filteredPositions: function () {
      let tempArray = [];
      tempArray = this.storePositions.map((el) => {
        const newEl = {
          position: {
            slot: "position",
            data: el,
          },
          occupied: {
            slot: "occupied",
          },
          backedup: "",
          preparing: "",
          status: {
            slot: "status",
          },
          actions: {
            slot: "actions",
            data: {},
          },
        };
        const totalCount = Object.values(el.attachments_count).reduce(
          (sum, count) => sum + count,
          0
        );
        newEl.position.data.totalAssignment = totalCount;
        return newEl;
      });
      const statusFilters = ["draft", "active", "retired", "inactive"];
      if (this.appliedFilters.some((val) => statusFilters.includes(val))) {
        tempArray = tempArray.filter((el2) =>
          this.appliedFilters.includes(el2.position.data.status)
        );
      }
      const occupiedFilters = [
        "Occupied",
        "Not Occupied",
        "1 FTE",
        "0.5 FTE",
        "Has Roles",
        "Does not Have Roles",
      ];
      if (this.appliedFilters.some((val) => occupiedFilters.includes(val))) {
        tempArray = tempArray.filter((el2) => {
          return this.appliedFilters.some((el3) => {
            let val = false;
            if (el3 === "Occupied") {
              val = el2.position.data.occupied === 1;
            } else if (el3 === "Not Occupied") {
              val = el2.position.data.occupied === 0;
            } else if (el3 === "1 FTE") {
              val = el2.position.data.capacity === 1;
            } else if (el3 === "0.5 FTE") {
              val = el2.position.data.capacity === 0.5;
            } else if (el3 === "Has Roles") {
              if (Object.keys(el2.position.data.attachments_count).length > 0) {
                if (el2.position.data.attachments_count.role > 0) {
                  val = true;
                }
              }
            } else if (el3 === "Does not Have Roles") {
              if (
                Object.keys(el2.position.data.attachments_count).length === 0
              ) {
                val = true;
              }
            }
            return val;
          });
        });
      }
      if (this.filterText && this.filterText.length > 0) {
        return tempArray.filter((el) => {
          return (
            el.position.data.name
              .toUpperCase()
              .includes(this.filterText.toUpperCase()) ||
            el?.position?.data?.code?.toString().includes(this.filterText)
          );
        });
      } else {
        return tempArray;
      }
    },
    paginatedAttachedRoles() {
      if (!this.searchMode) {
        return this.matchedData.slice(
          (this.paginationRoleModel.page - 1) * 10,
          (this.paginationRoleModel.page - 1) * 10 + 10
        );
      } else {
        return this.matchedData;
      }
    },
  },
  created() {
    this.applySavedFilters();
  },
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    await this.$store.dispatch("positionStore/loadPositions");
    const treeListElement = this.$refs.dataTableList?.$el;
    if (treeListElement) {
      treeListElement.addEventListener("click", this.handleTextContentClick);
    }
  },
  beforeDestroy() {
    const treeListElement = this.$refs.dataTableList?.$el;
    if (treeListElement) {
      treeListElement.removeEventListener("click", this.handleTextContentClick);
    }
  },
  methods: {
    handleTextContentClick(event) {
      const target = event.target.closest(".datatable-text-content");
      if (target) {
        const id = target?.getAttribute("data-position-id");
        if (id) {
          this.$router.push("/organization/positions/position/" + id);
        }
      }
    },
    applySavedFilters() {
      this.$store.dispatch("filterStore/loadFilters", this.storeFilterKey);
      const savedFilters = this.$store.state.filterStore.filters;
      if (savedFilters && savedFilters.length > 0) {
        this.appliedFilters = savedFilters;
        this.defaultSelected = savedFilters;
      }
    },
    async retirePosition() {
      try {
        await retirePosition(this.accountID, this.currentPosition.id);
        this.onCloseModal();
        await this.loadPositions();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    removeFilter(removedFilter) {
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.clearSelection = removedFilter;
    },
    filterEmployees(filters) {
      const newArray = filters
        .map((item) => {
          if (item.value !== null) {
            if (typeof item === "string") {
              return item;
            } else if (
              typeof item === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              item.hasOwnProperty("value")
            ) {
              return item.value;
            }
          }
        })
        .filter(Boolean);
      this.appliedFilters = newArray;
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.showFilterDialog = false;
    },
    statusColor(status) {
      if (status === "active") {
        return "green";
      } else {
        return "gray";
      }
    },
    handleDropdownClick(data, row) {
      // you can see the defined actions in dropdownMixin.js
      switch (data.value) {
        case "activate_action":
          this.showActivatePosition(row);
          break;
        case "delete_action":
          this.showDeletePosition(row);
          break;
        case "rename_action":
          this.showRenamePosition(row);
          break;
        case "attach_position_role":
          this.openRoleToPosition(row);
          break;
        case "retire_action":
          this.showRetirePosition(row);
          break;
        case "deactivate_action":
          this.showDeActivatePosition(row);
          break;
        case "reactivate_action":
          this.showReActivatePosition(row);
          break;
        case "cloned_action":
          this.showClonedPosition(row);
          break;
        default:
          break;
      }
    },
    async onChangeCapacity(capacity, row) {
      try {
        const id = row.position.data.id;
        if (id) {
          const payload = {
            name: row.position.data.name,
            account: this.accountID,
            capacity: capacity,
          };
          await updatePosition(this.accountID, id, payload);
        }
        await this.loadPositions();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    onFilter(data) {
      this.filterText = data;
      if (data) {
        this.paginationModel.page = 1;
      }
    },
    onCloseModal() {
      this.showAddPositionDialog = false;
      this.showActivatePositionDialog = false;
      this.showDeletePositionDialog = false;
      this.showRenamePositionDialog = false;
      this.currentPosition = null;
      this.showPositionRoleDialog = false;
      this.showClonedPositionDialog = false;
      this.showDeActivatePositionDialog = false;
      this.showReActivatePositionDialog = false;
      this.showRetirePositionDialog = false;
    },
    async savePosition(position) {
      try {
        this.$loader.setAppLoading(true);
        const payload = {
          name: position,
          account: this.accountID,
        };
        const { data } = await createPosition(this.accountID, payload);
        this.onCloseModal();
        await this.loadPositions();
        this.filterText = data.code.toString();
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    showActivatePosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showActivatePositionDialog = true;
    },
    showClonedPosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showClonedPositionDialog = true;
    },
    showDeletePosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showDeletePositionDialog = true;
    },
    showRenamePosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showRenamePositionDialog = true;
    },
    showDeActivatePosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showDeActivatePositionDialog = true;
    },
    showReActivatePosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showReActivatePositionDialog = true;
    },
    showRetirePosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showRetirePositionDialog = true;
    },
    async renamePosition(newName) {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          const payload = {
            name: newName,
            account: this.accountID,
          };
          await updatePosition(this.accountID, id, payload);
        }
        this.onCloseModal();
        await this.loadPositions();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async requestData(data) {
      if (data && data.page) {
        this.paginationModel.page = data.page;
      }
    },
    async loadPositions() {
      await this.$store.dispatch("positionStore/loadPositions");
    },
    async deletePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          await deletePosition(this.accountID, id);
        }
        this.onCloseModal();
        await this.loadPositions();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async activatePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          const payload = {
            name: this.currentPosition.name,
            status: "active",
            account: this.accountID,
          };
          await updatePosition(this.accountID, id, payload);
        }
        this.onCloseModal();
        await this.loadPositions();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async clonePosition() {
      try {
        const name = this.currentPosition?.name;
        const payload = {
          name: "Clone of " + name,
          account: this.accountID,
        };
        const { data } = await createPosition(this.accountID, payload);
        const objId = data.id;
        const attachmentPromises = this.currentPosition?.attachments.map(
          (att) => {
            const payload1 = {
              attachment_id: att.id,
            };
            return attachRoleToPosition(this.accountID, objId, payload1);
          }
        );
        await Promise.all(attachmentPromises);
        this.onCloseModal();
        await this.loadPositions();
        this.filterText = data.code.toString();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    openDetail(data) {
      this.setRoles(data);
      this.$router.push(
        "/organization/positions/position/" + data.position.data.id
      );
    },
    setRoles(data) {
      this.$store.dispatch("updatePositionRoleData", data);
    },
    async openRolePopup(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.showPositionRoleDialog = true;
      this.modalHeaderState = {
        status: data.position.data.status,
        icon: "org_icon_position",
        label: "Position",
        code: data.position.data.code ? "#" + data.position.data.code : "null",
        name: data.position.data.name,
      };
      this.menuItems[0].count = data.position.data.attachments_count.role
        ? data.position.data.attachments_count.role
        : 0;
      await this.loadRoles();
    },
    getMatchedData(data, attachments) {
      const attachmentIds = attachments.map((att) => att.id);
      return data.filter((item) => attachmentIds.includes(item.id));
    },
    transformData(input) {
      return input.map((item) => {
        const transformedItem = {
          id: item.id,
          parentId: item.parentId,
          data: {
            type: item.data.type,
            code: item.data.code ? item.data.code : "null",
            name: item.data.name,
          },
          attachedTo: {
            data: [],
          },
          attachedToItems: {
            ...item.attachedTo,
          },
          assignment: item.assignment,
          status: item.status,
        };
        const typeCountMap = item.attachedTo.data.reduce((acc, att) => {
          acc[att.type] = (acc[att.type] || 0) + 1;
          return acc;
        }, {});
        transformedItem.attachedTo.data = Object.entries(typeCountMap).map(
          ([type, quantity]) => ({ type, quantity })
        );
        return transformedItem;
      });
    },
    async requestRoleData(data) {
      console.log("on request data: ", data);
      if (data && data.page) {
        this.paginationRoleModel.page = data.page;
        // this.loadRoles(data.page);
      }
    },
    async loadRoles(pageNo = 1) {
      const { data } = await getListOfRoles(this.accountID, pageNo, 500);
      if (data && data.results) {
        this.dataSourceRoles = data.results.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.parentId = -1;
          newEl.status = el.status;
          newEl.data = {
            type: "role",
            code: el.code + "",
            name: el.name,
            status: el.status,
          };
          newEl.info = {
            ...el,
          };
          newEl.attachedTo = {
            data: el.attachments,
          };
          const totalCount = Object.values(el.attachments_count).reduce(
            (sum, count) => sum + count,
            0
          );
          newEl.assignment = totalCount;
          return newEl;
        });
        const roleData = this.getMatchedData(
          this.dataSourceRoles,
          this.currentPosition.attachments
        );
        this.paginationRoleModel.total = roleData.length;
        const matchedRoleData = this.transformData(roleData);
        const itemsToAdd = [];
        const responsibilities = await getListofResponsibility(
          this.accountID,
          1,
          500
        );
        let responsibilitiesCopy = [];
        if (responsibilities.data && responsibilities.data.results) {
          responsibilitiesCopy = responsibilities.data.results.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            const typeCountRes = el.attachments.reduce((acc, att) => {
              acc[att.type] = (acc[att.type] || 0) + 1;
              return acc;
            }, {});
            el.attachments = Object.entries(typeCountRes).map(
              ([type, quantity]) => ({ type, quantity })
            );
            const totalCount = Object.values(el.attachments_count).reduce(
              (sum, count) => sum + count,
              0
            );
            newEl.attachedTo = {
              data: el.attachments,
            };
            newEl.assignment = totalCount;
            return newEl;
          });
        }
        matchedRoleData.forEach((el) => {
          const responsibilitiesAttachments =
            el.attachedToItems.data?.filter(
              (ra) => ra.type == "responsibility"
            ) || [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
            if (tempItemCopy) {
              tempItemCopy.id = itemsToAdd.length + 400;
              tempItemCopy.parentId = el.id;
              itemsToAdd.push(tempItemCopy);
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(matchedRoleData.concat(itemsToAdd))
        );
        this.matchedData = finalArray;
        const attachedResponsibilityIds = new Set(
          this.currentPosition.attachments.map((attachment) => attachment.id)
        );
        const unmatchedResponsibilities = this.dataSourceRoles
          .filter(
            (item) =>
              item.data.type === "role" &&
              !attachedResponsibilityIds.has(item.id)
          )
          .filter((item) => item.status !== "retired");
        this.items = unmatchedResponsibilities.map((item) => ({
          id: item.data.code,
          code: item.data.code,
          name: item.data.name,
          status: item.status,
        }));
      }
    },
    closeRolePopup() {
      this.showPositionRoleDialog = false;
    },
    openRoleToPosition(data) {
      this.currentPosition = JSON.parse(JSON.stringify(data.position.data));
      this.loadRoles();
      this.showRoleToPosition = true;
    },
    handleItemSelected(data) {
      const matchedItem = this.dataSourceRoles.find(
        (item) => "#" + item.data.code + " " + item.data.name === data
      );
      if (matchedItem) {
        this.attachmentId = matchedItem.id;
      }
    },
    async addHandleItemSelected(data1) {
      this.$loader.show();
      try {
        const payload = {
          name: data1.name,
          account: this.accountID,
        };
        const { data } = await createRole(this.accountID, payload);
        this.attachmentId = data.id;
        await this.loadRoles();
        this.attachedRole();
      } catch (e) {
        console.log("e: ", e);
      }
      this.$loader.hide();
    },
    async attachedRole() {
      this.$loader.setAppLoading(true);
      try {
        const objId = this.currentPosition?.id;
        const payload = {
          attachment_id: this.attachmentId,
        };
        const { data } = await attachRoleToPosition(
          this.accountID,
          objId,
          payload
        );
        this.menuItems[0].count = data.attachments_count.role
          ? data.attachments_count.role
          : 0;
        this.showRoleToPosition = false;
        await this.loadRoles();
        await this.loadPositions();
        const roleData = this.getMatchedData(
          this.dataSourceRoles,
          data.attachments
        );
        const matchedRoleData = this.transformData(roleData);
        const itemsToAdd = [];
        const responsibilities = await getListofResponsibility(
          this.accountID,
          1,
          500
        );
        let responsibilitiesCopy = [];
        if (responsibilities.data && responsibilities.data.results) {
          responsibilitiesCopy = responsibilities.data.results.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            return newEl;
          });
        }
        matchedRoleData.forEach((el) => {
          const responsibilitiesAttachments =
            el.attachedToItems.data?.filter(
              (ra) => ra.type == "responsibility"
            ) || [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
            if (tempItemCopy) {
              tempItemCopy.id = itemsToAdd.length + 400;
              tempItemCopy.parentId = el.id;
              itemsToAdd.push(tempItemCopy);
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(matchedRoleData.concat(itemsToAdd))
        );
        this.matchedData = finalArray;
        this.menuItems[0].count = data.attachments_count.role
          ? data.attachments_count.role
          : 0;
      } catch (e) {
        console.log("e: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async deActivatePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          await deActivatePosition(this.accountID, id);
        }
        this.onCloseModal();
        await this.loadPositions();
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async reActivatePosition() {
      try {
        const id = this.currentPosition?.id;
        if (id) {
          await reActivatePosition(this.accountID, id);
        }
        this.onCloseModal();
        await this.loadPositions();
      } catch (e) {
        console.error("error: ", e);
      }
    },
  },
  watch: {
    filteredPositions(newVal) {
      if (newVal) {
        this.paginationModel.total = newVal.length;
      }
    },
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [
          {
            text: "Position",
            sortable: true,
            value: "Position",
          },
          {
            text: "Primary",
            sortable: false,
            value: "primary ",
            multiple: true,
            data: [
              {
                text: "Occupied by / ",
                img: "employee-organization/primary-assignment-grey.svg",
              },
              {
                text: "Acting As",
                img: "employee-organization/theater.svg",
              },
            ],
          },
          {
            text: "Backed up by",
            sortable: false,
            value: "backedup",
            img: "employee-organization/backedupicon.svg",
          },
          {
            text: "Preparing",
            sortable: false,
            value: "prepares",
            img: "icons/preparing.svg",
          },
          {
            text: "Status",
            sortable: false,
            value: "status",
          },
          {
            text: "",
            sortable: false,
            value: "actions",
          },
        ];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
<style>
.v-data-table__wrapper .datatable-text-content a {
  text-decoration: none !important;
}
.v-data-table__wrapper .datatable-text-content a:hover {
  cursor: url("../assets/cursorArrow.svg"), auto;
  background-color: unset;
  text-underline-offset: unset;
}
</style>
<style scoped>
.border-card {
  border: 1px solid #e5e5e5;
  padding: 16px;
  border-radius: 8px;
}
.text-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.active-badge {
  padding: 4px 12px;
  background-color: #f9f5ff;
  color: #6941c6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
}
.employee-view-weight-bold {
  font-weight: 600;
}
.text-empty-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #101828;
}
.text-empty-description {
  color: #475467;
  font-weight: 400;
  line-height: 1.5rem;
}
.add-position-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: #5b2cc5;
}
.v-data-table__wrapper .datatable-text-content > div {
  padding: 15px 15px 15px 6px;
  cursor: url("../assets/cursorArrow.svg"), auto;
}
.v-data-table__wrapper .datatable-text-content > div:hover {
  background-color: #f2edff;
  padding: 15px 15px 15px 6px;
  box-shadow: 0px 4px 4px 0px #0000000f;
  border-radius: 8px;
  cursor: url("../assets/cursorArrow.svg"), auto;
}
.icon-container {
  display: inline-block;
  padding: 8px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.icon-container:hover {
  background-color: #f2edff;
  box-shadow: 0 4px 4px 0px rgba(102, 102, 102, 0.1);
}
.icon-svg {
  display: block;
}
</style>
<style scoped>
::v-deep .no-record-found .wrapper {
  border: none !important;
}
</style>
