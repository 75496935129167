var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OrgObjectTreeList',{attrs:{"store-persist-filters":true,"store-filters-key-prefix":'readinessCatalogsRoleAttachment',"topLevel":"responsibility","actionButtonIcon":"icons/attach_white.svg","removeBorder":true,"optionalColumns":[
        {
          name: "dashboard",
          caption: "Dashboard"
        },
        {
          name: "assignment",
          caption: "Assignment"
        },
        {
          name: "attachedto",
          caption: "Attached to"
        },
    ],"dataSource":_vm.tableData,"pagination":_vm.pagination,"btnText":'Attach'},on:{"onRequest":function($event){return _vm.$emit('onRequest', $event)},"onSearchMode":function($event){return _vm.$emit('onSearchMode', $event)},"onButtonAction":function($event){return _vm.$emit('onButtonAction')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }