var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EXTooltip',{attrs:{"title":`${_vm.value} FTE - ${_vm.formattedOccupied} occupied`,"placement":"top"}},[_c('ExGenericSelector',{attrs:{"tipSide":"left","variant":"border","width":194,"persistent":"","leftTransition":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"position_capacity_icon",class:{
        'capacity_icon_normal_size': _vm.size === 'normal',
        'capacity_icon_small_size': _vm.size === 'small'
      }},[_c('div',{staticClass:"circle",class:{
            bg_transparent: _vm.value === 1 && _vm.occupied === 'not',
            bg_gray_transparent: _vm.value === 1 && _vm.occupied === '0.5',
            bg_gray_gray: _vm.value === 1 && _vm.occupied === '2x0.5',
            bg_gray: _vm.value === 1 && _vm.occupied === '1',
            bg_black_transparent: _vm.value === 0.5 && _vm.occupied === 'not',
            bg_black_gray: _vm.value === 0.5 && _vm.occupied === '0.5',
            position_icon_br_normal: _vm.size === 'normal',
            position_icon_br_small: _vm.size === 'small',
          }}),_c('div',{staticClass:"position_capacity_icon_wrapper",class:{
        'normal_icon_size': _vm.size === 'normal',
        'small_icon_size': _vm.size === 'small'
      }})])]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{staticClass:"position_capacity_icon_container",attrs:{"flat":""}},[_c('div',{staticClass:"position_capacity_icon_content"},[_c('div',{staticClass:"selector",class:_vm.size === 'normal' ? 'capacity_icon_left_negative_normal' : 'capacity_icon_left_negative_small'},[_c('div',{staticClass:"content d-flex flex-column align-start pa-3 pb-4"},[_c('div',{staticClass:"text_fte d-flex justify-space-between text_container"},[_c('span',{staticClass:"text_fte mb-4"},[_vm._v("Capacity")]),_c('v-btn',{staticClass:"capacity_icon_close_btn",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.show = false}}},[_c('Icon',{attrs:{"name":"interface_close","size":"extra-small"}})],1)],1),_c('v-radio-group',{staticClass:"ml-2 mb-2",attrs:{"hide-details":"","data-name":"radiogroup"},model:{value:(_vm.fteValue),callback:function ($$v) {_vm.fteValue=$$v},expression:"fteValue"}},[_c('v-radio',{staticClass:"mb-5",attrs:{"color":"#7f56d9","data-name":"fte1radio","value":1},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('div',{staticClass:"fte_1 me-5"},[_c('div',{staticClass:"modal_position_capacity_icon_wrapper normal_icon_size"})]),_c('div',{staticClass:"text_fte"},[_vm._v("1 FTE")])])]},proxy:true}])}),_c('v-radio',{attrs:{"color":"#7f56d9","data-name":"fte05radio","value":0.5},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('div',{staticClass:"fte_05 me-5"},[_c('div',{staticClass:"fte_circle"})]),_c('div',{staticClass:"text_fte"},[_vm._v("0.5 FTE")])])]},proxy:true}])})],1)],1)])])])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }