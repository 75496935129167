<template>
  <v-container fluid>
    <div class="custom-chatter-view">
      <NoRecordFound
        v-if="chatterData.length === 0"
        title="Employee Chatter"
        message="Employee Chatter"
        btnTitle=""
        class="chatter-not-found mt-8"
      />
      <ActivityStream
        class="mx-8"
        :dataStream="chatterData"
        :show-load-more-button="totalChatterData > chatterData.length"
        @onLoadMore="getEmployeesChatter"
      />
    </div>
  </v-container>
</template>
<script>
import ActivityStream from "@components/Chatter/ActivityStream.vue";
import { getEmployeeChatter } from "@/api";
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
export default {
  name: "EmployeePathToReadinessView",
  components: {
    ActivityStream,
    NoRecordFound,
  },
  data() {
    return {
      chatterData: [],
      totalChatterData: [],
      accountID: "",
    };
  },
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    await this.getEmployeesChatter();
  },
  methods: {
    async getEmployeesChatter() {
      const empId = this.$route.params.id;
      const page = Math.ceil(this.chatterData.length / 10);
      const { data } = await getEmployeeChatter(
        this.accountID,
        empId,
        page + 1
      );
      if (data && data.count) {
        this.totalChatterData = data.count;
      }
      if (data && data.results) {
        let chatterItems = data.results.map((el) => {
          const date = new Date(el.recorded_on);
          const milliseconds = date.getTime();
          const millisecondsString = milliseconds.toString();
          let nameParts = el.recorded_by_name.split(" ");
          const newEl = {};
          newEl.id = el.id;
          if (nameParts.length === 2) {
            newEl.avatar = {
              picture: "",
              firstName: nameParts[0],
              lastName: nameParts[1],
            };
          } else {
            newEl.avatar = {
              picture: "",
              firstName: el.recorded_by_name,
              lastName: "",
            };
          }
          newEl.IsExIQtiveBot = false;
          newEl.message = el.note;
          newEl.created_on = millisecondsString;
          newEl.IsHistory = el.is_history;
          return newEl;
        });
        this.chatterData.push(...chatterItems);
      }
    },
  },
};
</script>
<style>
.custom-chatter-view {
  margin-top: -35px;
}
.chatter-not-found .badge {
  display: none;
}
.chatter-not-found button {
  display: none;
}
</style>
