import { render, staticRenderFns } from "./EmployeeAssigmentTag.vue?vue&type=template&id=04826708&scoped=true"
import script from "./EmployeeAssigmentTag.vue?vue&type=script&lang=js"
export * from "./EmployeeAssigmentTag.vue?vue&type=script&lang=js"
import style0 from "./EmployeeAssigmentTag.vue?vue&type=style&index=0&id=04826708&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04826708",
  null
  
)

export default component.exports