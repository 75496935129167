<template>
  <v-container fluid>
    <StandarPageWithList
      title="Employees Organization"
      :activeNo="activeEmployees"
      :empty="!storeEmployees.length"
    >
      <template #action>
        <Button
          @onClick="showAddDialog = true"
          :normal="'normal-active'"
          class="add-employee-btn"
          label="Add Employee"
          :icon="'add-icon.svg'"
        ></Button>
      </template>
      <template #empty-content>
        <div class="d-flex flex-column align-center">
          <h3 class="mb-2">No employees found</h3>
          <p class="text-center mb-8">
            You need some employees in your <br />
            organization
          </p>
          <Button
            class="mb-6"
            :plusIcon="'add-icon.svg'"
            :general="true"
            :label="'Add Employee'"
            @onClick="showAddDialog = true"
          ></Button>
        </div>
      </template>
      <div class="px-5 mb-5">
        <SearchBar
          @onSearch="onFilter"
          type="button"
          :label="appliedFilters.length > 0 ? 'More Filters' : 'Filters'"
          :length="appliedFilters.length"
          :chipText="appliedFilters"
          ghostText="Search"
          :add="false"
          @onClick="showFilterDialog = true"
          @removeFilter="removeFilter"
          :valueSearch="filterText"
        ></SearchBar>
      </div>
      <DataTable
        :pagination="paginationModel"
        :headers="headers"
        :data="filteredEmployees"
        @onRequest="requestData"
      >
        <template #no-data> </template>
        <template #employee="{ row }">
          <EmployeeCard
            class="my-4"
            :firstName="row.employee.data.first_name"
            :lastName="row.employee.data.last_name"
            :position="row.employee.data.title"
            :photoUrl="row.employee.data.avatar"
            :availability="row.employee.data.availability"
            :nameUrl="`employees/${row.employee.data.id}/pathtoreadiness`"
            :hideAvailabilitySelector="
              row.employee.data?.primary_positions?.length > 0 || false
            "
            :isAllocated="
              row.employee.data?.primary_positions?.length > 0 || false
            "
            :showLinkUnderline="false"
            @update:availability="
              onUpdateAvailability({
                employee: row.employee.data,
                value: $event,
              })
            "
          ></EmployeeCard>
        </template>
        <template #primary="{ row }">
          <EmployeeAssigmentTag
            class="my-4"
            :position-code="
              row.employee.data?.primary_positions[0]?.code.split(' ')[0] ||
              null
            "
            :position-name="row.employee.data?.primary_positions[0]?.name"
            :type="
              row.employee.data?.current_status !== 'active'
                ? 'not allowed'
                : row.employee.data?.primary_positions?.length > 0
                ? getTypeOpAssignmentTag(
                    row.employee.data?.primary_positions[0]
                  )
                : 'no primary assignment'
            "
            :fte="
              row.employee.data?.primary_positions.length > 0
                ? row.employee.data.primary_positions[0].allocation
                : null
            "
            :photoUrl="row.employee.data.avatar"
            :firstName="row.employee.data.first_name"
            :lastName="row.employee.data.last_name"
            @onPrimaryAssign="onShowPrimaryAssignWizard(row)"
            @onClose="onRelievePrimaryAssignment(row)"
          />
        </template>
        <template #status="{ row }">
          <StatusChip
            :text="row.employee.data.current_status"
            :textColor="statusColor(row.employee.data.current_status).textColor"
            :borderColor="
              statusColor(row.employee.data.current_status).borderColor
            "
            :backgroundColor="
              statusColor(row.employee.data.current_status).backgroundColor
            "
            :dotColor="statusColor(row.employee.data.current_status).dotColor"
          />
        </template>
        <template #actions="{ row }">
          <DropDownMenu
            data-testid="employee-operations-menu"
            @onClick="handleDropdownClick($event, row)"
            :is-open="false"
            :dropdown-items="
              getEmployeeDropdownItems(row.employee.data.current_status)
            "
          >
            <template #activator>
              <div class="icon-container">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </DropDownMenu>
        </template>
      </DataTable>
    </StandarPageWithList>
    <AddEmployeeModal
      :show="showAddDialog"
      @close="onCloseModal"
      @onSave="saveEmployee"
      :propEmployee="currentEmployee"
      @updateavailability="onUpdateAvailability($event)"
      :loading="isLoading"
    ></AddEmployeeModal>
    <TerminateEmployee
      @onAction="terminateEmployee"
      @onClose="onCloseModal"
      :show="showTerminateDialog"
      :info="terminateMessage"
      :avatar="{
        picture: '',
        firstName: `${currentEmployee?.first_name || 'U'}`,
        lastName: `${currentEmployee?.last_name || 'S'}`,
      }"
    />
    <DeleteEmployee
      @onAction="deleteEmployee"
      @onClose="onCloseModal"
      :show="showDeleteDialog"
      :info="deleteMessage"
      :avatar="{
        picture: '',
        firstName: `${currentEmployee?.first_name || 'U'}`,
        lastName: `${currentEmployee?.last_name || 'S'}`,
      }"
    />
    <ActivateEmployee
      :show="showActivateDialog"
      :employee="currentEmployee"
      @onClose="onCloseModal"
      @onActiveEmployee="activateEmployee"
    ></ActivateEmployee>
    <ReActivateEmployee
      :show="showReactivateDialog"
      :employee="currentEmployee"
      @onClose="onCloseModal"
      @onActiveEmployee="reactivateEmployee"
    ></ReActivateEmployee>
    <SearchModal
      :dialog="showFilterDialog"
      @back="showFilterDialog = false"
      @checkedItemsChanged="filterEmployees"
      :clearSelection="clearSelection"
      filterType="Employee"
      :filterGroups="filterGroups"
      :statusOptions="statusOptions"
      :modalWidth="800"
      :defaultSelected="defaultSelected"
    />
    <EditModalPicture
      :dialog="showPictureDialog"
      :avatar="editAvatar"
      @croppedImage="handleCroppedAvatar"
      @closeModal="closeAvatarModal"
      @removeModal="removeAvatarModal"
    />
    <ExNotification
      :notification="notificationInstance"
      :timeToClose="5000"
      type="User"
      :notes="notifyMessage"
      :actionTitle2="actionChangeAvatar"
      notificationType="success"
      dateTime="2 mins ago"
      position="top-right"
      :userFirstName="userFirstName"
      :userLastName="userLastName"
      :userPicture="userPicture"
      @closeNotification="closeNotification"
      @onLeftButtonClick="onLeftButtonClick"
    />
    <ExEmployeePrimaryAssignmentWizard
      label="Position"
      v-model="showPrimaryAssignWizard"
      :items="positionsForAssign"
      @onAssignPosition="onAssignPositionToEmployee"
      :currentEmployee="currentEmployee"
    />
    <GiveAccessToEmployeeModal
      @checkEmail="checkEmailUser"
      @onSave="giveAccessToEmployee"
      @onChangeContentType="giveAccessDataType = $event"
      v-model="showGiveAccessModal"
      :contentTypeProp="giveAccessDataType"
      :existingUser="existingUser"
      :current-employee="currentEmployee"
    />
    <RelievePrimaryAssignment
      v-if="showRelievePrimaryAssignment"
      :show="showRelievePrimaryAssignment"
      :avatar="{
        picture: currentEmployee.avatar,
        firstName: currentEmployee.first_name,
        lastName: currentEmployee.last_name,
      }"
      :current-position="currentEmployee?.primary_positions[0]?.name || ''"
      @onClose="showRelievePrimaryAssignment = false"
      @onAction="removePositionAssignmentToEmployee"
    />
  </v-container>
</template>
<script>
import StandarPageWithList from "@components/common/PageHeaderWithButton.vue";
import DataTable from "@components/DataTable/DataTable.vue";
import SearchBar from "@components/SearchBar/SearchBar.vue";
import EmployeeCard from "@components/organizations/employees/EmployeeCard.vue";
import AddEmployeeModal from "@components/organizations/employees/AddEmployeeModal.vue";
import EmployeeAssigmentTag from "@components/organizations/positions/EmployeeAssigmentTag.vue";
import StatusChip from "@components/organizations/common/StatusChip.vue";
import Button from "@components/buttons/Button.vue";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import TerminateEmployee from "@components/organizations/employees/TerminateEmployee.vue";
import DeleteEmployee from "@components/organizations/employees/DeleteEmployee.vue";
import ExEmployeePrimaryAssignmentWizard from "@components/PositionPicker/ExEmployeePrimaryAssignmentWizard.vue";
import GiveAccessToEmployeeModal from "@components/GiveAccessEmployee/GiveAccessToEmployeeModal.vue";
import RelievePrimaryAssignment from "@components/organizations/employees/RelievePrimaryAssignment.vue";
import { userCheckEmail, createUser, getListofUser } from "@/api";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import {
  createEmployee,
  updateEmployee,
  terminateEmployee,
  deleteEmployee,
  reactivateEmployee,
  updatePosition,
  removePositionAssignmentForEmployee,
} from "@/api";
import { getListOfPositions } from "@/api";
import ActivateEmployee from "@components/modals/ActiveEmployee.vue";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import ReActivateEmployee from "@components/modals/ReActivateEmployee.vue";
import EditModalPicture from "@components/EditPicture/EditModal.vue";
import ExNotification from "@components/Toast/Notification.vue";
import { mapState } from "vuex";
export default {
  name: "EmployeeView",
  components: {
    SearchBar,
    DataTable,
    EmployeeCard,
    EmployeeAssigmentTag,
    StatusChip,
    StandarPageWithList,
    Button,
    AddEmployeeModal,
    DropDownMenu,
    TerminateEmployee,
    DeleteEmployee,
    ActivateEmployee,
    SearchModal,
    ReActivateEmployee,
    EditModalPicture,
    ExNotification,
    ExEmployeePrimaryAssignmentWizard,
    GiveAccessToEmployeeModal,
    RelievePrimaryAssignment,
  },
  data() {
    return {
      storeFilterKey: "organizationEmployees",
      showAddDialog: false,
      showTerminateDialog: false,
      showDeleteDialog: false,
      showActivateDialog: false,
      showReactivateDialog: false,
      showRelievePrimaryAssignment: false,
      addEmployeeForm: {},
      filterText: "",
      accountID: "",
      employees: [],
      currentEmployee: null,
      paginationModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      showFilterDialog: false,
      showGiveAccessModal: false,
      giveAccessDataType: "step1",
      giveAccessData: null,
      clearSelection: "",
      appliedFilters: [],
      defaultSelected: [],
      showPictureDialog: false,
      editAvatar: "",
      croppedImage: null,
      notificationInstance: false,
      userFirstName: "",
      userLastName: "",
      userPicture: "",
      notifyMessage: "",
      showPrimaryAssignWizard: false,
      positionsForAssign: [],
      actionChangeAvatar: null,
      existingUser: null,
      filterGroups: [
        {
          label: "Vacancy",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Vacant", value: "vacant" },
          ],
        },
        {
          label: "Availability",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Availability 0.5 FTE", value: "avail_05_fte" },
            { label: "Availability 1 FTE", value: "avail_1_fte" },
          ],
        },
        {
          label: "Access",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "No Access", value: "no_access" },
            { label: "Invited", value: "invited" },
            { label: "Has Access", value: "has_access" },
            { label: "Suspended", value: "suspended" },
            { label: "Blocked", value: "blocked" },
          ],
        },
      ],
      statusOptions: ["draft", "active", "terminated"],
      isLoading: false,
    };
  },
  mixins: [dropdownMixin],
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    await this.loadEmployees();
  },
  computed: {
    ...mapState("employeeStore", {
      storeEmployees: (state) => state.employees,
    }),
    activeEmployees() {
      return this.filteredEmployees.filter(
        (el) => el.employee.data.current_status == "active"
      ).length;
    },
    filteredEmployees: function () {
      let resultArray = [];
      resultArray = this.storeEmployees.map((el) => {
        const newEl = {
          employee: {
            slot: "employee",
            data: el,
          },
          primary: {
            slot: "primary",
          },
          actingas: "",
          backedup: "",
          preparing: "",
          status: {
            slot: "status",
            data: {
              text: "Active",
              textColor: "green",
              borderColor: "green",
              backgroundColor: "green",
              dotColor: "green",
            },
          },
          actions: {
            slot: "actions",
            data: {},
          },
        };
        return newEl;
      });
      if (this.filterText && this.filterText.length > 0) {
        resultArray = resultArray.filter((el) => {
          const firstName = el.employee.data.first_name.toUpperCase();
          const lastName = el.employee.data.last_name.toUpperCase();
          const fullName = `${firstName} ${lastName}`;
          const title = el.employee.data.title.toUpperCase();
          const filterTextUpper = this.filterText.toUpperCase();

          return (
            fullName.includes(filterTextUpper) ||
            title.includes(filterTextUpper)
          );
        });
      }
      // filter by status
      const statusFilters = ["draft", "active", "terminated"];
      if (this.appliedFilters.some((val) => statusFilters.includes(val))) {
        resultArray = resultArray.filter((el2) =>
          this.appliedFilters.includes(el2.employee.data.current_status)
        );
      }
      // filter by availability
      const availabilityFilters = [
        "Availability 0.5 FTE",
        "Availability 1 FTE",
      ];
      if (
        this.appliedFilters.some((val) => availabilityFilters.includes(val))
      ) {
        resultArray = resultArray.filter((el2) => {
          return this.appliedFilters.some((el3) => {
            let val = false;
            if (el3 === "Availability 0.5 FTE") {
              val = el2.employee.data.availability === 0.5;
            } else if (el3 === "Availability 1 FTE") {
              val = el2.employee.data.availability === 1;
            }
            return val;
          });
        });
      }
      resultArray = resultArray.sort((a, b) => {
        if (a.employee.data.first_name < b.employee.data.first_name) return -1;
        if (a.employee.data.first_name > b.employee.data.first_name) return 1;
        if (a.employee.data.last_name < b.employee.data.last_name) return -1;
        if (a.employee.data.last_name > b.employee.data.last_name) return 1;
        return 0;
      });
      return resultArray;
    },
    deleteMessage: function () {
      return `${this?.currentEmployee?.first_name || ""} ${
        this?.currentEmployee?.last_name || ""
      } is about to be Deleted. This action is irreversible.`;
    },
    terminateMessage: function () {
      return `${this?.currentEmployee?.first_name || ""} ${
        this?.currentEmployee?.last_name || ""
      } is about to be terminated. We will relieve the employee of all their assignments. We will also suspend access for the employee to this account.`;
    },
  },
  created() {
    this.applySavedFilters();
  },
  methods: {
    onRelievePrimaryAssignment(row) {
      try {
        this.currentEmployee = row.employee.data;
        this.showRelievePrimaryAssignment = true;
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async removePositionAssignmentToEmployee() {
      try {
        await removePositionAssignmentForEmployee(
          this.accountID,
          this.currentEmployee.primary_positions[0].id
        );
        this.showRelievePrimaryAssignment = false;
        await this.loadEmployees();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    getTypeOpAssignmentTag(positionData) {
      if (positionData.vacant) {
        return "vacant";
      } else if (positionData.occupied) {
        return "occupied";
      } else if (positionData.blocked) {
        return "blocked";
      } else if (positionData.not_allowed) {
        return "not allowed";
      }
    },
    async onAssignPositionToEmployee(position) {
      try {
        const payload = {
          name: position.name,
          account: this.accountID,
          employee: this.currentEmployee.id,
        };
        await updatePosition(this.accountID, position.id, payload);
        this.showPrimaryAssignWizard = false;
        await this.loadEmployees();
      } catch (e) {
        console.error("error: ", e);
      }
    },
    async onShowPrimaryAssignWizard(row) {
      this.currentEmployee = row.employee.data;
      const { data } = await getListOfPositions(this.accountID, 1, 500);
      let allocationSum = 0;
      if (
        this.currentEmployee.primary_positions &&
        this.currentEmployee.primary_positions.length > 0
      ) {
        this.currentEmployee.primary_positions.forEach((el) => {
          allocationSum += el.allocation;
        });
      }
      const employeeVacant = this.currentEmployee.availability - allocationSum;

      const allocationsArray = [];

      this.$store.state.employeeStore.employees.forEach((employee) => {
        if (
          employee.primary_positions &&
          employee.primary_positions.length > 0
        ) {
          employee.primary_positions.forEach((positionAssignment) => {
            const newEl = {
              id: positionAssignment.position,
              allocation: positionAssignment.allocation,
            };
            const itemExist = allocationsArray.some((el) => el.id == newEl.id);
            if (!itemExist) {
              allocationsArray.push(newEl);
            }
          });
        }
      });

      if (data && data.results) {
        const positionsWithAllocation = data.results.map((el) => {
          const allocationItem = allocationsArray.find(
            (allocItem) => allocItem.id === el.id
          );
          el.allocation = allocationItem ? allocationItem.allocation : 0;
          return el;
        });

        this.positionsForAssign = positionsWithAllocation.filter((el) => {
          const positionVacancy = el.capacity - el.allocation;
          return positionVacancy >= employeeVacant;
        });

        this.showPrimaryAssignWizard = true;
      }
    },
    applySavedFilters() {
      this.$store.dispatch("filterStore/loadFilters", this.storeFilterKey);
      const savedFilters = this.$store.state.filterStore.filters;
      if (savedFilters && savedFilters.length > 0) {
        this.appliedFilters = savedFilters;
        this.defaultSelected = savedFilters;
      }
    },
    async giveAccessToEmployee(payload) {
      await createUser(this.accountID, payload);
      this.showGiveAccessModal = false;
    },
    async checkEmailUser(email) {
      const { data } = await userCheckEmail(this.accountID, { email });
      const emailIsBlocked = data?.access;
      const emailIsUsed = data?.user;
      if (emailIsUsed) {
        const { data } = await getListofUser(this.accountID);
        const userList = data.results;
        const user = userList.find((el) => el.user.email == email);
        this.existingUser = {
          firstName: user.user.first_name,
          lastName: user.user.last_name,
        };
        this.giveAccessDataType = "emailUsed";
      } else if (emailIsBlocked) {
        this.giveAccessDataType = "emailBlocked";
      } else {
        this.giveAccessDataType = "step2";
      }
    },
    onGiveAccessToEmployee(row) {
      const { id, first_name, last_name, availability, avatar } =
        row.employee.data;
      const employeeObj = {
        id,
        firstName: first_name,
        lastName: last_name,
        availabilityVal: availability,
        avatar,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showGiveAccessModal = true;
    },
    removeFilter(removedFilter) {
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.clearSelection = removedFilter;
    },
    filterEmployees(filters) {
      const newArray = filters
        .map((item) => {
          if (item.value !== null) {
            if (typeof item === "string") {
              return item;
            } else if (
              typeof item === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              item.hasOwnProperty("value")
            ) {
              return item.value;
            }
          }
        })
        .filter(Boolean);
      this.appliedFilters = newArray;
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.showFilterDialog = false;
    },
    statusColor(status) {
      if (status === "active") {
        return {
          textColor: "green",
          borderColor: "green",
          backgroundColor: "green",
          dotColor: "green",
        };
      } else if (status === "draft") {
        return {
          textColor: "gray",
          borderColor: "gray",
          backgroundColor: "gray",
          dotColor: "gray",
        };
      } else if (status === "terminated") {
        return {
          textColor: "black",
          borderColor: "black",
          backgroundColor: "gray",
          dotColor: "black",
        };
      } else {
        return {
          textColor: "gray",
          borderColor: "gray",
          backgroundColor: "gray",
          dotColor: "gray",
        };
      }
    },
    handleDropdownClick(data, row) {
      switch (data.value) {
        case "activate_action":
          this.onActivateEmployee(row);
          break;
        case "delete_action":
          this.onDeleteEmployee(row);
          break;
        case "edit_action":
          this.onEditEmployee(row);
          break;
        case "change_picture_action":
          this.onChangeAvatarFile(row);
          break;
        case "give_access_action":
          this.onGiveAccessToEmployee(row);
          break;
        case "suspend_access_action":
          break;
        case "give_back_access_action":
          break;
        case "terminate_action":
          this.onTerminateEmployee(row);
          break;
        case "reactivate_action":
          this.onReactivateEmployee(row);
          break;
        default:
          break;
      }
    },
    onFilter(data) {
      this.filterText = data;
    },
    onCloseModal() {
      this.showAddDialog = false;
      this.showActivateDialog = false;
      this.showDeleteDialog = false;
      this.showTerminateDialog = false;
      this.showReactivateDialog = false;
      this.currentEmployee = null;
      this.showPictureDialog = false;
    },
    async terminateEmployee() {
      try {
        const { id } = this.currentEmployee;
        if (id) {
          await terminateEmployee(this.accountID, id);
        }
        this.showTerminateDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async reactivateEmployee() {
      try {
        const { id } = this.currentEmployee;
        if (id) {
          await reactivateEmployee(this.accountID, id);
        }
        this.showReactivateDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async deleteEmployee() {
      try {
        const { id } = this.currentEmployee;
        if (id) {
          await deleteEmployee(this.accountID, id);
        }
        this.showDeleteDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async saveEmployee(employee) {
      try {
        this.$loader.setAppLoading(true);
        const { id, first_name, last_name, title } = employee;
        const payload = {
          first_name,
          last_name,
          title,
        };
        if (id) {
          await updateEmployee(this.accountID, id, payload);
        } else {
          await createEmployee(this.accountID, payload);
        }
        this.showAddDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
        this.filterText = `${first_name} ${last_name}`;
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async requestData(data) {
      if (data && data.page) {
        this.paginationModel.page = data.page;
      }
    },
    async loadEmployees() {
      await this.$store.dispatch("employeeStore/loadEmployees");
      await this.$store.dispatch("positionStore/loadPositions");
    },
    async onUpdateAvailability(data) {
      try {
        const { id, first_name, last_name, title } = data.employee;
        const payload = {
          first_name,
          last_name,
          title,
          availability: data.value,
        };
        await updateEmployee(this.accountID, id, payload);
        await this.loadEmployees();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    onEditEmployee(row) {
      const { id, first_name, last_name, title, availability, avatar } =
        row.employee.data;
      const employeeObj = {
        id,
        first_name,
        last_name,
        title,
        availability,
        avatar,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showAddDialog = true;
    },
    onTerminateEmployee(row) {
      const { id, first_name, last_name, title } = row.employee.data;
      const employeeObj = {
        id,
        first_name,
        last_name,
        title,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showTerminateDialog = true;
      this.showPictureDialog = false;
    },
    onReactivateEmployee(row) {
      const { id, first_name, last_name, availability, avatar } =
        row.employee.data;
      const employeeObj = {
        id,
        firstName: first_name,
        lastName: last_name,
        availability,
        picture: avatar,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showReactivateDialog = true;
    },
    onDeleteEmployee(row) {
      const { id, first_name, last_name, title } = row.employee.data;
      const employeeObj = {
        id,
        first_name,
        last_name,
        title,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showDeleteDialog = true;
      this.showPictureDialog = false;
    },
    onActivateEmployee(row) {
      const { id, first_name, last_name, availability, avatar, title } =
        row.employee.data;
      const employeeObj = {
        id,
        firstName: first_name,
        lastName: last_name,
        availability,
        picture: avatar,
        title: title,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showActivateDialog = true;
      this.showPictureDialog = false;
    },
    async activateEmployee() {
      try {
        const { id, firstName, lastName, title } = this.currentEmployee;
        const payload = {
          current_status: "active",
          first_name: firstName,
          last_name: lastName,
          title: title,
        };
        if (id) {
          await updateEmployee(this.accountID, id, payload);
        }
        let currentActiveEmployees =
          this.$store.getters["employeeStore/activeEmployeesCount"] || 0;
        this.$store.commit(
          "employeeStore/setActiveEmployeesCount",
          currentActiveEmployees + 1
        );
        this.showActivateDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    onChangeAvatarFile(row) {
      const { id, first_name, last_name, title, availability, avatar } =
        row.employee.data;
      this.editAvatar = avatar;
      this.showPictureDialog = true;
      const employeeObj = {
        id,
        first_name,
        last_name,
        title,
        availability,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
    },
    async handleCroppedAvatar(croppedImage) {
      this.croppedImage = croppedImage;
      const { id, first_name, last_name, title } = this.currentEmployee;
      const employeeObj = {
        first_name,
        last_name,
        title,
        avatar: this.croppedImage,
      };
      const { data } = await updateEmployee(this.accountID, id, employeeObj);
      if (data.avatar) {
        this.currentEmployee.avatar = data.avatar;
      }
      await this.loadEmployees();
      this.actionChangeAvatar = "Open Employee File";
      this.notificationInstance = false;
      this.userFirstName = first_name;
      this.userLastName = last_name;
      this.userPicture = this.croppedImage;
      this.notifyMessage = `Just Uploaded new picture for employee ${first_name} ${last_name}`;
      this.showPictureDialog = false;
    },
    closeAvatarModal() {
      this.showPictureDialog = false;
    },
    async removeAvatarModal() {
      const { id, first_name, last_name, title } = this.currentEmployee;
      const employeeObj = {
        first_name,
        last_name,
        title,
        avatar: "",
      };
      await updateEmployee(this.accountID, id, employeeObj);
      await this.loadEmployees();
      this.actionChangeAvatar = null;
      this.notificationInstance = false;
      this.userFirstName = first_name;
      this.userLastName = last_name;
      this.userPicture = this.croppedImage;
      this.notifyMessage = `Just removed picture for employee ${first_name} ${last_name}`;
      this.showPictureDialog = false;
    },
    closeNotification() {
      this.notificationInstance = false;
    },
    onLeftButtonClick() {
      const link = document.createElement("a");
      link.href = this.currentEmployee.avatar;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [
          {
            text: "Employee",
            sortable: true,
            value: "Employee",
          },
          {
            text: "Primary",
            sortable: false,
            value: "primary ",
            img: "icons/briefcase.svg",
          },
          {
            text: "Acting As",
            sortable: false,
            value: "actingas",
            img: "icons/theater.svg",
          },
          {
            text: "Provides backup to",
            sortable: false,
            value: "backedup",
            img: "icons/briefcaseoutlined.svg",
          },
          {
            text: "Prepares for",
            sortable: false,
            value: "prepares",
            img: "icons/preparing.svg",
          },
          {
            text: "Status",
            sortable: false,
            value: "status",
          },
          {
            text: "",
            sortable: false,
            value: "actions",
          },
        ];
      },
    },
    data: {
      type: Array,
      default() {
        return [
          {
            employee: {
              slot: "employee",
              data: {
                firstName: "Olivia",
                lastName: "Rhye",
                position: "HR Manager",
                photoUrl: "",
                availability: 1,
                isAllocated: false,
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "no primary assignment",
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Active",
                textColor: "green",
                borderColor: "green",
                backgroundColor: "green",
                dotColor: "green",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                firstName: "Olivia",
                lastName: "Rhye",
                position: "HR Manager",
                photoUrl: "",
                availability: 1,
                isAllocated: true,
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "",
                fte: 1,
                photoUrl: "",
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Active",
                textColor: "green",
                borderColor: "green",
                backgroundColor: "green",
                dotColor: "green",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
                photoUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
                availability: 0.5,
                isAllocated: false,
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "no primary assignment",
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Draft",
                textColor: "gray",
                borderColor: "gray",
                backgroundColor: "gray",
                dotColor: "gray",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "occupied",
                fte: 1,
                photoUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Retired",
                textColor: "gray",
                borderColor: "gray",
                backgroundColor: "gray",
                dotColor: "gray",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "vacant",
                fte: 0.5,
                photoUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Retired",
                textColor: "gray",
                borderColor: "gray",
                backgroundColor: "gray",
                dotColor: "gray",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "blocked",
                fte: 0.5,
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Retired",
                textColor: "gray",
                borderColor: "gray",
                backgroundColor: "gray",
                dotColor: "gray",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "not allowed",
                fte: 0.5,
              },
            },
            actingas: "",
            backedup: "",
            preparing: "",
            status: {
              slot: "status",
              data: {
                text: "Retired",
                textColor: "gray",
                borderColor: "gray",
                backgroundColor: "gray",
                dotColor: "gray",
              },
            },
            actions: {
              slot: "actions",
              data: {},
            },
          },
        ];
      },
    },
  },
  watch: {
    filteredEmployees(newVal) {
      if (newVal) {
        this.paginationModel.total = newVal.length;
      }
    },
  },
};
</script>
<style scoped>
.border-card {
  border: 1px solid #e5e5e5;
  padding: 16px;
  border-radius: 8px;
}
.text-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.active-badge {
  padding: 4px 12px;
  background-color: #f9f5ff;
  color: #6941c6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
}
.employee-view-weight-bold {
  font-weight: 600;
}
.text-empty-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #101828;
}
.text-empty-description {
  color: #475467;
  font-weight: 400;
  line-height: 1.5rem;
}
.add-employee-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: #5b2cc5;
}
.icon-container {
  display: inline-block;
  padding: 8px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.icon-container:hover {
  background-color: #f2edff;
  box-shadow: 0 4px 4px 0px rgba(102, 102, 102, 0.1);
}

.icon-svg {
  display: block;
}
</style>
