import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListofUser = (account_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/access/`,
    params: { page: 1, size: 500 },
  });
  return result;
};

export const createUser = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/user/`,
    data: data,
  });
  return result;
};

export const manageAppAccess = (account_id, user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/user/${user_id}/access/`,
    data: data,
  });
  return result;
};

export const getAppAccess = (account_id, user_id, data) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/user/${user_id}/access/`,
    data: data,
  });
  return result;
};

export const changePassword = (user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.USER}${user_id}/password/`,
    data: data,
  });
  return result;
};

export const resetPassword = (user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.USER}${user_id}/password/`,
    data: data,
  });
  return result;
};

export const userStateUpdateSuspend = (account_id, access_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/access/${access_id}/suspend/`,
    data: data,
  });
  return result;
};

export const userResendInvite = (user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.USER}${user_id}/resend-invite/`,
    data: data,
  });
  return result;
};

export const userStateUpdateGiveBack = (account_id, access_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/access/${access_id}/un-suspend/`,
    data: data,
  });
  return result;
};

export const userStateUpdate = (account_id, user_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/user/${user_id}/`,
    data: data,
  });
  return result;
};

export const userCheckEmail = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `api/common/account/${account_id}/test/email/`,
    data: data,
  });
  return result;
};

export const resetUserPassword = (data) => {
  let result = axios(false)({
    method: "post",
    url: `api/common/reset-password/`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result;
};

export const sendNewPasswordData = (data) => {
  let result = axios(false)({
    method: "post",
    url: `api/common/user/reset/password/`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result;
};
