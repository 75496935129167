<template>
  <v-container fluid>
    <PageHeaderWithButton title="" hideActiveNo :hideTopSection="true">
      <template #action>
        <span></span>
      </template>
      <ExBreadcrumbs class="px-3 pt-4 pb-6" :breadcrumbsList="breadcrumItems" />
      <ReadinessCatalogs
        :dataSource="sanitizeDataSource"
        :allDataSource="searchItems"
        @onRowOperation="handleReadinessOperations($event)"
        @onAddRole="actions.showAddRoleDialog = true"
        @onAddResponsibility="actions.showAddResponsibilityDialog = true"
        @onRequest="onRequestPagination($event)"
        @changeView="onChangeView($event)"
        @appliedFilter="appliedFilter($event)"
        @searchTextData="searchTextData()"
        :pagination="paginationModel"
        :menu-items="menuItemsProp"
        @detach="detachItems($event)"
      />
    </PageHeaderWithButton>
    <AddRole
      v-model="actions.showAddRoleDialog"
      @onSave="saveNewRole($event)"
    />
    <ActivateRole
      v-model="actions.activateRoleDialog"
      @onSave="activateRole"
      :role="currentRole"
    />
    <RenameRole
      v-model="actions.showRenameRoleDialog"
      @onSave="renameRole"
      :role="currentRole?.data?.name || ''"
    />
    <AttachedRole
      v-model="actions.attachedRoleDialog"
      @onSave="attachRoleToResponsibility()"
      :items="items"
      @item-selected="handleItemSelected"
      @add-item-selected="addHandleItemSelected"
      :existingNamesList="existingResponsibilitiesNames"
      :parentName="currentRole?.data?.name || ''"
    />
    <DeleteRole
      v-model="actions.deleteRoleDialog"
      @onDelete="deleteRole"
      :role="currentRole?.data?.name || ''"
    />
    <AddResponsibility
      v-model="actions.showAddResponsibilityDialog"
      @onSave="createResponsibility($event)"
    />
    <RenameResponsibility
      v-model="actions.showRenameResponsibilityDialog"
      @onSave="renameResponsibility($event)"
      :responsibility="currentResponsibility?.data?.name || ''"
    />
    <DeleteResponsibility
      v-model="actions.deleteResponsibilityDialog"
      :responsibility="currentResponsibility?.data?.name || ''"
      @onDelete="deleteResponsibility"
    />
    <ActivateResponsibility
      v-model="actions.showActivateResponsiblityDialog"
      :responsibility="currentResponsibility?.data?.name || ''"
      @onSave="activateResponsibility"
    />
    <RetireResponsibility
      v-model="actions.showRetireResponsibilityDialog"
      :responsibility="currentResponsibility?.data?.name || ''"
      @onSave="retireResponsibility"
    />
    <RetiredRole
      v-model="actions.retiredRoleDialog"
      @onSave="retiredRole"
      :role="currentRole"
    />
    <DeActivateRole
      v-model="actions.deActivateRoleDialog"
      @onSave="deActivateRole"
      :role="currentRole"
    />
    <ReActivateRole
      v-model="actions.reActivateRoleDialog"
      @onSave="reActivateRole"
      :role="currentRole"
    />
    <DeActivateResponsibility
      v-model="actions.showDeActivateResponsiblityDialog"
      :responsibility="currentResponsibility?.data?.name || ''"
      @onSave="deActivateResponsibility"
    />
    <ReActivateResponsibility
      v-model="actions.showReActivateResponsiblityDialog"
      :responsibility="currentResponsibility?.data?.name || ''"
      @onSave="reActivateResponsibility"
    />
    <DetachResponsibilityDialog
      v-model="actions.showDetachResponsibilityDialog"
      :responsibility="currentResponsibility?.data || {}"
      :role="getParentName || ''"
      @onAction="detachResponsibility"
    />
  </v-container>
</template>
<script>
import ExBreadcrumbs from "@components/breadcrumbs/ExBreadcrumbs.vue";
import ReadinessCatalogs from "@components/RolesCatalog/ReadinessCatalogs.vue";
import responsibilityMixin from "@components/views/mixins/responsibilityMixin";
import roleMixin from "@components/views/mixins/roleMixin";
import PageHeaderWithButton from "@components/common/PageHeaderWithButton.vue";

export default {
  name: "RolesCatalogView",
  components: {
    ReadinessCatalogs,
    ExBreadcrumbs,
    PageHeaderWithButton,
  },
  mixins: [responsibilityMixin, roleMixin],
  data() {
    return {
      // originalDataSource: [],
      dataSource: [],
      dataSourceRoles: [],
      dataSourceResponsibilities: [],
      dataSourceKnowledge: [],
      accountId: null,
      actions: {
        showAddRoleDialog: false,
        showAddResponsibilityDialog: false,
        activateRoleDialog: false,
        attachedRoleDialog: false,
        retiredRoleDialog: false,
        deleteRoleDialog: false,
        showRenameRoleDialog: false,
        showRenameResponsibilityDialog: false,
        deleteResponsibilityDialog: false,
        showActivateResponsiblityDialog: false,
        showRetireResponsibilityDialog: false,
        deActivateRoleDialog: false,
        reActivateRoleDialog: false,
        showDeActivateResponsiblityDialog: false,
        showReActivateResponsiblityDialog: false,
        showDetachResponsibilityDialog: false,
      },
      paginationModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      appliedFilters: [],
      currentRole: null,
      currentResponsibility: null,
      items: [],
      attachmentId: null,
      currentResponse: null,
      modalHeaderState: undefined,
      dataResponsibilitiesBadge: [],
      employeeData: [],
      badgeFrameItems: [],
      menuItems: [
        { title: "Description", count: 0 },
        { title: "Checklist", count: 0 },
        { title: "Badge Holders", count: 0 },
        { title: "Assignments", count: 0 },
        { title: "Defaults", count: 0 },
        { title: "Chatter", count: 0 },
      ],
      badgeData: null,
      breadcrumItems: [
        {
          text: "Readiness Catalogs",
          disabled: false,
          href: "/readiness/catalogs",
        },
        {
          text: "Roles",
          disabled: false,
          href: "/readiness/catalogs/roles",
        },
      ],
      existingResponsibilitiesNames: [],
      searchItems: [],
    };
  },
  async mounted() {
    this.accountId = JSON.parse(localStorage.getItem("currentAccountID"));
    await this.loadNewDataSource();
  },
  watch: {
    sanitizeDataSource(newVal) {
      if (newVal) {
        this.paginationModel.total = newVal.filter(
          (el) => el.parentId == -1
        ).length;
      }
    },
  },
  methods: {
    appliedFilter(data) {
      this.appliedFilters = data;
      if (data.length > 0) {
        this.dataSource = this.originalDataSource.filter((item) =>
          data.includes(item.status)
        );
        const totalCount = this.dataSource.filter((el) => el.parentId == -1);
        this.paginationModel.total = totalCount.length;
        this.paginationModel.page = 1;
      } else {
        this.dataSource = this.originalDataSource;
        const totalCount = this.dataSource.filter((el) => el.parentId == -1);
        this.paginationModel.total = totalCount.length;
      }
      this.sanitizeDataSource = this.dataSource;
    },
    searchTextData() {
      this.searchItems = this.originalDataSource;
    },
    async onChangeView(tab) {
      if (tab == 0) {
        if (!this.breadcrumItems.some((el) => el.text == "Roles")) {
          const existingItemIndex = this.breadcrumItems.findIndex(
            (el) => el.text == "Responsibilities"
          );
          if (existingItemIndex > -1) {
            this.breadcrumItems.splice(existingItemIndex, 1);
          }
          this.breadcrumItems.push({
            text: "Roles",
            disabled: false,
            href: "/readiness/catalogs/roles",
          });
        }
        if (this.$route.path != "/readiness/catalogs/roles") {
          await this.$router.push("/readiness/catalogs/roles");
        }
      } else if (tab == 1) {
        if (!this.breadcrumItems.some((el) => el.text == "Responsibilities")) {
          const existingItemIndex = this.breadcrumItems.findIndex(
            (el) => el.text == "Roles"
          );
          if (existingItemIndex > -1) {
            this.breadcrumItems.splice(existingItemIndex, 1);
          }
          this.breadcrumItems.push({
            text: "Responsibilities",
            disabled: false,
            href: "/readiness/catalogs/responsibilities",
          });
        }
        if (this.$route.path != "/readiness/catalogs/responsibilities") {
          await this.$router.push("/readiness/catalogs/responsibilities");
        }
      } else if (tab == 2) {
        // knowledge
      }
    },
    onRequestPagination(paginationData) {
      if (paginationData.page) {
        this.paginationModel.page = paginationData.page;
      }
    },
    closeDialog() {
      this.actions.showAddRoleDialog = false;
      this.actions.showAddResponsibilityDialog = false;
      this.actions.activateRoleDialog = false;
      this.actions.deleteRoleDialog = false;
      this.actions.showRenameResponsibilityDialog = false;
      this.actions.deleteRoleDialog = false;
      this.actions.deleteResponsibilityDialog = false;
      this.actions.showActivateResponsiblityDialog = false;
      this.actions.showRetireResponsibilityDialog = false;
      this.actions.deActivateRoleDialog = false;
      this.actions.reActivateRoleDialog = false;
      this.actions.showDeActivateResponsiblityDialog = false;
      this.actions.showReActivateResponsiblityDialog = false;
      this.actions.showDetachResponsibilityDialog = false;
    },
    getResponsibility() {
      const attachedResponsibilityIds = new Set(
        this.currentRole.info.attachments
          .filter((attachment) => attachment.type === "responsibility")
          .map((attachment) => attachment.id)
      );
      this.existingResponsibilitiesNames = this.storeResponsibilities
        .filter((el) => attachedResponsibilityIds.has(el.id))
        .map((el2) => {
          const newEl = {
            id: el2.id,
            name: el2.name,
          };
          return newEl;
        });
      const unmatchedResponsibilities = this.storeResponsibilities.filter(
        (item) => item.status !== "retired"
      );
      this.items = unmatchedResponsibilities.map((item) => ({
        id: item.id,
        code: item.code,
        name: item.name,
        status: item.status,
      }));
    },
    handleReadinessOperations(operation) {
      if (operation.data.data.type == "role") {
        this.currentRole = JSON.parse(JSON.stringify(operation.data));
      }
      if (operation.data.data.type == "responsibility") {
        this.currentResponsibility = JSON.parse(JSON.stringify(operation.data));
      }
      switch (operation.item.value) {
        case "activate_role":
          this.actions.activateRoleDialog = true;
          break;
        case "attach_responsibility_role":
          this.actions.attachedRoleDialog = true;
          this.getResponsibility();
          break;
        case "retired_role":
          this.actions.retiredRoleDialog = true;
          break;
        case "delete_role":
          this.actions.deleteRoleDialog = true;
          break;
        case "rename_role":
          this.actions.showRenameRoleDialog = true;
          break;
        case "rename_responsibility":
          this.actions.showRenameResponsibilityDialog = true;
          break;
        case "delete_responsibility":
          this.actions.deleteResponsibilityDialog = true;
          break;
        case "activate_responsibility":
          this.actions.showActivateResponsiblityDialog = true;
          break;
        case "retire_responsibility":
          this.actions.showRetireResponsibilityDialog = true;
          break;
        case "de_activate_role":
          this.actions.deActivateRoleDialog = true;
          break;
        case "re_activate_role":
          this.actions.reActivateRoleDialog = true;
          break;
        case "de_activate_responsibility":
          this.actions.showDeActivateResponsiblityDialog = true;
          break;
        case "re_activate_responsibility":
          this.actions.showReActivateResponsiblityDialog = true;
          break;
        case "detach_responsibility":
          this.actions.showDetachResponsibilityDialog = true;
          break;
        default:
          break;
      }
    },
    handleItemSelected(data) {
      const matchedItem = this.storeResponsibilities.find(
        (item) => "#" + item.code + " " + item.name === data
      );
      if (matchedItem) {
        this.attachmentId = matchedItem.id;
      }
    },
    async attachRoleToResponsibility() {
      this.$loader.setAppLoading(true);
      try {
        await this.attachedRole();
        this.$toast.success("Responsibility attached successfully.");
      } catch (e) {
        console.log("e: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async addHandleItemSelected(item) {
      this.$loader.show();
      const { data } = await this.addResponsibility(item.name);
      this.attachmentId = data.id;
      await this.attachedRole();
      this.actions.attachedRoleDialog = false;
      await this.loadDataSource();
      this.$loader.hide();
      this.$toast.success("Responsibility attached successfully.");
    },
  },
  computed: {
    menuItemsProp() {
      return [
        {
          title: "Roles",
          count: this.storeRoles.filter(
            (el) => el?.status && el?.status == "active"
          ).length,
        },
        {
          title: "Responsibilities",
          count: this.storeResponsibilities.filter(
            (el) => el?.status && el?.status == "active"
          ).length,
        },
        // { title: "Knowledge", count: 0 }, hide for now
      ];
    },
    getParentName() {
      if (this.currentResponsibility) {
        const {
          parentId,
          data: { attached_roles },
        } = this.currentResponsibility;
        const matchingRole = attached_roles.find(
          (role) => role.id === parentId
        );
        return matchingRole ? matchingRole.name : null;
      } else {
        return "";
      }
    },
  },
};
</script>
