import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListofEmployee = async (account_id, page_no, size) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/employee/`,
    params: { page: page_no, size: size },
  });
  return result;
};

export const createEmployee = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/`,
    data: data,
  });
  return result;
};
export const updateEmployee = (account_id, employee_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/employee/${employee_id}/`,
    data: data,
  });
  return result;
};

export const employeeStateUpdate = (account_id, user_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/employee/${user_id}/`,
    data: data,
  });
  return result;
};

export const terminateEmployee = (account_id, user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${user_id}/terminate/`,
  });
  return result;
};

export const reactivateEmployee = (account_id, employee_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/employee/${employee_id}/reactivate/`,
  });
  return result;
};

export const deleteEmployee = (account_id, user_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/employee/${user_id}/`,
  });
  return result;
};

export const getEmployeeById = (account_id, emp_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/employee/${emp_id}/`,
  });
  return result;
};

export const getEmployeeChatter = (account_id, emp_id, page = 1) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/employee/${emp_id}/chatter/`,
    params: { page: page },
  });
  return result;
};

export const removePositionAssignmentForEmployee = (
  account_id,
  assignment_id
) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/position-assigment/${assignment_id}`,
  });
  return result;
};
