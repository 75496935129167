var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('StandarPageWithList',{attrs:{"title":"Employees Readiness","activeNo":_vm.activeEmployees,"empty":!_vm.storeEmployees.length},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('Button',{staticStyle:{"display":"none"},attrs:{"icon":'add-icon.svg',"normal":'normal-active',"label":"Add Employee 1"}})]},proxy:true},{key:"empty-content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h3',{staticClass:"mb-2"},[_vm._v("No employees found")]),_c('p',{staticClass:"text-center mb-8"},[_vm._v(" You need some employees in your "),_c('br'),_vm._v(" readiness ")])])]},proxy:true}])},[_c('div',{staticClass:"px-5 mb-5"},[_c('SearchBar',{attrs:{"type":"button","label":_vm.appliedFilters.length > 0 ? 'More Filters' : 'Filters',"length":_vm.appliedFilters.length,"chipText":_vm.appliedFilters,"ghostText":"Search","add":false,"valueSearch":_vm.filterText},on:{"onSearch":_vm.onFilter,"onClick":function($event){_vm.showFilterDialog = true},"removeFilter":_vm.removeFilter}})],1),_c('DataTable',{attrs:{"pagination":_vm.paginationModel,"headers":_vm.headers,"data":_vm.filteredEmployees},on:{"onRequest":_vm.requestData},scopedSlots:_vm._u([{key:"no-data",fn:function(){return undefined},proxy:true},{key:"employee",fn:function({ row }){return [_c('EmployeeCard',{staticClass:"my-4",attrs:{"firstName":row.employee.data.first_name,"lastName":row.employee.data.last_name,"position":row.employee.data.title,"photoUrl":row.employee.data.avatar,"availability":row.employee.data.availability,"nameUrl":`/organization/employees/${row.employee.data.id}/pathtoreadiness`,"isAllocated":false,"showLinkUnderline":false},on:{"update:availability":function($event){return _vm.onUpdateAvailability({
              employee: row.employee.data,
              value: $event,
            })}}})]}},{key:"readiness",fn:function(){return [_c('div',{staticClass:"readiness-width"},[_c('ReadinessBar',{attrs:{"barValue":70,"type":"readiness","maxValue":150}})],1)]},proxy:true},{key:"primary",fn:function({ row }){return [(row.employee.data.primary_readiness > 0)?_c('div',{staticClass:"readiness-width"},[_c('ReadinessBar',{attrs:{"barValue":row.employee.data.primary_readiness,"type":"readiness","maxValue":150}})],1):_c('span',{staticClass:"notAssigned"},[_vm._v("Not Assigned")])]}},{key:"backedup",fn:function({ row }){return [(row.employee.data.backup_readiness > 0)?_c('div',{staticClass:"readiness-width-backup"},[_c('ReadinessBar',{attrs:{"barValue":row.employee.data.backup_readiness,"type":"readiness","maxValue":150}})],1):_c('span',{staticClass:"notAssigned"},[_vm._v("Not Assigned")])]}},{key:"preparing",fn:function({ row }){return [(row.employee.data.preparing_for_readiness > 0)?_c('div',{staticClass:"readiness-width"},[_c('ReadinessBar',{attrs:{"barValue":row.employee.data.preparing_for_readiness,"type":"readiness","maxValue":150}})],1):_c('span',{staticClass:"notAssigned"},[_vm._v("Not Assigned")])]}},{key:"actingas",fn:function({ row }){return [(row.employee.data.current_readiness > 0)?_c('div',{staticClass:"readiness-width"},[_c('ReadinessBar',{attrs:{"barValue":row.employee.data.current_readiness,"type":"readiness","maxValue":150}})],1):_c('span',{staticClass:"notAssigned"},[_vm._v("Not Assigned")])]}},{key:"actions",fn:function({ row }){return [_c('DropDownMenu',{attrs:{"data-testid":"employee-operations-menu","is-open":false,"dropdown-items":_vm.getReadinessEmployeeDropdownItems(
              row.employee.data.current_status
            )},on:{"onClick":function($event){return _vm.handleDropdownClick($event, row)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"icon-container"},[_c('svg',{staticClass:"icon-svg",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z","stroke":"#344054","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]},proxy:true}],null,true)})]}}])})],1),_c('TerminateEmployee',{attrs:{"show":_vm.showTerminateDialog,"info":_vm.terminateMessage,"avatar":{
      picture: '',
      firstName: `${_vm.currentEmployee?.first_name || 'U'}`,
      lastName: `${_vm.currentEmployee?.last_name || 'S'}`,
    }},on:{"onAction":_vm.terminateEmployee,"onClose":_vm.onCloseModal}}),_c('DeleteEmployee',{attrs:{"show":_vm.showDeleteDialog,"info":_vm.deleteMessage,"avatar":{
      picture: '',
      firstName: `${_vm.currentEmployee?.first_name || 'U'}`,
      lastName: `${_vm.currentEmployee?.last_name || 'S'}`,
    }},on:{"onAction":_vm.deleteEmployee,"onClose":_vm.onCloseModal}}),_c('SearchModal',{attrs:{"dialog":_vm.showFilterDialog,"clearSelection":_vm.clearSelection,"filterType":"Readiness Employee","filterGroups":_vm.filterGroups,"statusOptions":_vm.statusOptions,"modalWidth":800,"defaultSelected":_vm.defaultSelected},on:{"back":function($event){_vm.showFilterDialog = false},"checkedItemsChanged":_vm.filterEmployees}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }