<template>
  <v-container fluid class="px-6">
    <h1>Settings</h1>
    <SettingsMenu
      :items="settingOptions"
      :value="selectedTab"
      class="ex-menu-div"
      data-test="setting-menu"
      @onChange="handleTabChange"
    >
    </SettingsMenu>
    <v-tabs-items v-model="selectedTab" class="custom-tabs">
      <v-tab-item v-for="(item, index) in settingOptions" :key="index">
        <v-card>
          <v-card-text class="custom-view" v-if="selectedTab === 0">
            <ExCreateEmployeeWizard
              :accessLevel="accessLevel"
              @created="onCreatedUser"
              @onSearch="onFilter"
              @onModalFilter="filteredModalUsers"
              @onFilterRemove="removeFilter"
              :clearSelection="clearSelection"
              :appliedFilters="appliedFilters"
              :valueSearch="filterText"
            />
            <DataTable
              class="user-tr mt-5"
              :pagination="paginationModel"
              :headers="headers"
              :data="filteredUsers"
              @onRequest="requestData"
            >
              <template #no-data> </template>
              <template #user="{ row }">
                <UserCard
                  :user="{
                    name:
                      row.user.data.user.first_name +
                      ' ' +
                      row.user.data.user.last_name,
                    email: row.user.data.user.email,
                    avatar: '',
                    linkedToEmployee: false,
                    lostAccount:
                      row.user.data.user.state == 'suspended' ||
                      row.user.data.user.state == 'disabled',
                    employeeStatus: row.user.data.user.state,
                    userStatus: row.user.data.user.state_str,
                  }"
                ></UserCard>
              </template>
              <template #access="{ row }">
                <UserPermissionBadge
                  v-for="chipData in row.user.data.accessChip"
                  :key="chipData.appDropdown"
                  :permissionType="chipData.permissionType"
                  :appDropdown="chipData.appDropdown"
                  @onClick="onRemoveAppAccess(chipData.appDropdown, row)"
                />
              </template>
              <template #lastlogin="{ row }">
                <div>
                  {{ formatDate(row.user.data.updated_on) }}
                </div>
              </template>
              <template #status="{ row }">
                <div>
                  <UserStatusBadge
                    :access="row.user.data.user.state"
                    :status="
                      getUserAccess(
                        row.user.data.user.state,
                        row.user.data.is_suspended
                      )
                    "
                  />
                </div>
              </template>
              <template #actions="{ row }">
                <div>
                  <DropDownMenu
                    @onClick="handleDropdownClick($event, row)"
                    :is-open="false"
                    :dropdown-items="getSettingsUsersItems(row.user.data)"
                  >
                    <template #activator>
                      <div class="icon-container">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-svg"
                        >
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </template>
                  </DropDownMenu>
                </div>
              </template>
            </DataTable>
          </v-card-text>
          <v-card-text v-if="selectedTab === 2">
            <AppIntegrationsList />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogTitle" persistent max-width="480px">
      <div class="pop-up">
        <div class="modal_header">
          <div class="d-flex flex-row">
            <h3 class="text-black-600 mt-5 ml-4">Change Password</h3>
            <CloseIcon className="ml-auto ma-4" @click="reset" />
          </div>
        </div>
        <div class="modal_content mt-4 px-6">
          <v-form ref="password">
            <p class="text-black-500 mb-2">Password</p>
            <v-text-field
              class="input mb-4"
              required
              placeholder="Enter new password"
              outlined
              color="rgba(208, 213, 221, 1)"
              base-color="rgba(208, 213, 221, 1)"
              name="email"
              v-model="password"
              id="password"
              type="password"
              :hide-details="true"
              :rules="validatePassword"
              autofocus
            ></v-text-field>
          </v-form>
        </div>
        <div class="modal_action d-flex justify-end px-6 py-6">
          <MainButton
            className="ml-2"
            :disabled="!canNext"
            @click="onChangePassword()"
            data-test-name="save"
          >
            <span>Save</span>
          </MainButton>
        </div>
      </div>
    </v-dialog>
    <ExActionsPopUp
      v-if="resendInviteDialog"
      headline="Resend Invite"
      :htmlInfo="resendInviteDialogDescription"
      actionLabel="Resend"
      :value="resendInviteDialog"
      :primary="true"
      :avatar="{
        picture: '',
        firstName: currentSelectedRow?.user?.first_name || '',
        lastName: currentSelectedRow?.user?.last_name || '',
      }"
      @onAction="resendInviteAction"
      @input="resendInviteDialog = false"
    />
    <v-dialog v-model="dialogTitle1" persistent max-width="480px">
      <div class="pop-up">
        <div class="modal_header">
          <div class="d-flex flex-row">
            <h3 class="text-black-600 mt-5 ml-4">Manage Apps Access</h3>
            <CloseIcon className="ml-auto ma-4" @click="reset" />
          </div>
        </div>
        <div class="modal_content mt-4 px-6">
          <v-row>
            <v-col cols="4" v-for="access in appAccessTypes" :key="access.key">
              <v-checkbox
                v-model="appPermissions"
                :label="access.appDropdown"
                :value="access.appDropdown"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
        <div class="modal_action d-flex justify-end px-6 py-6">
          <MainButton
            className="ml-2"
            @click="onSaveAppAccess()"
            data-test-name="save"
          >
            <span>Save</span>
          </MainButton>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import ExCreateEmployeeWizard from "@components/ExCreateEmployeeWizard.vue";
import SettingsMenu from "@components/SettingsMenu/SettingsMenu.vue";
import DataTable from "@components/DataTable/DataTable.vue";
import ExActionsPopUp from "@components/common/ExActionsPopUp.vue";
import {
  getListofUser,
  userResendInvite,
  userStateUpdateSuspend,
  userStateUpdateGiveBack,
  changePassword,
  manageAppAccess,
} from "@/api";
import UserCard from "@components/common/UserCard.vue";
import UserPermissionBadge from "@components/UserPermissionBadge/UserPermissionBadge.vue";
import UserStatusBadge from "@components/UserStatusBadge/UserStatusBadge.vue";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import CloseIcon from "@components/svgs/CloseIcon.vue";
import MainButton from "@components/buttons/MainButton.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import AppIntegrationsList from "@components/AppIntegrationsList/AppIntegrationsList.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
export default {
  name: "SettingView",
  data() {
    const accessPermission = JSON.parse(
      localStorage.getItem("accessPermission")
    );
    const accessLevel = accessPermission?.is_owner ? "owner" : "co-owner";

    return {
      selectedTab: 0,
      settingOptions: [
        { title: "Users", count: 0 },
        { title: "Subscription", count: 0 },
        { title: "Integrations", count: 0 },
      ],
      dialog: false,
      email: "",
      accessLevel,
      headers: [
        {
          text: "User",
          sortable: true,
          value: "user",
        },
        {
          text: "Access",
          sortable: false,
          value: "access",
        },
        {
          text: "Last login",
          sortable: false,
          value: "lastlogin",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      accountID: "",
      filterText: "",
      userList: [],
      appAccessTypes: [
        {
          key: "has_readiness_access",
          permissionType: "App Permission",
          appDropdown: "Proficiency",
        },
        {
          key: "has_knowledge_access",
          permissionType: "App Permission",
          appDropdown: "Knowledge",
        },
        {
          key: "has_performance_access",
          permissionType: "App Permission",
          appDropdown: "Performance",
        },
        {
          key: "has_compensation_access",
          permissionType: "App Permission",
          appDropdown: "Compensation",
        },
      ],
      paginationModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      currentSelectedRow: {},
      dialogTitle: false,
      dialogTitle1: false,
      resendInviteDialog: false,
      appPermissions: [],
      password: "",
      validatePassword: [(v) => !!v || "Password is required"],
      appliedFilters: [],
      clearSelection: "",
    };
  },
  mixins: [dropdownMixin],
  created() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.getUserList();
  },
  computed: {
    resendInviteDialogDescription() {
      const firstName = this.currentSelectedRow?.user?.first_name || "";
      const lastName = this.currentSelectedRow?.user?.last_name || "";
      return `You are about to resend invite to ${firstName} ${lastName}`;
    },
    canNext() {
      if (this.password != "") return true;
      return false;
    },
    filteredUsers: function () {
      let resultArray = [];
      resultArray = this.userList.map((el) => {
        const newEl = {
          user: {
            slot: "user",
            data: el,
          },
          access: {
            slot: "access",
          },
          lastlogin: {
            slot: "lastlogin",
          },
          status: {
            slot: "status",
          },
          actions: {
            slot: "actions",
            data: {},
          },
        };
        return newEl;
      });
      if (this.filterText && this.filterText.length > 0) {
        resultArray = resultArray.filter((el) => {
          const firstName = el.user.data.user.first_name.toUpperCase();
          const lastName = el.user.data.user.last_name.toUpperCase();
          const fullName = `${firstName} ${lastName}`;
          const filterTextUpper = this.filterText.toUpperCase();
          return fullName.includes(filterTextUpper);
        });
      }
      if (this.appliedFilters && this.appliedFilters.length > 0) {
        const userFilters = [
          "has access",
          "suspended",
          "blocked",
          "Is Employee",
          "Is Not Employee",
        ];
        if (this.appliedFilters.some((val) => userFilters.includes(val))) {
          resultArray = resultArray.filter((el) => {
            return this.appliedFilters.some((el3) => {
              let val = false;
              if (el3 === "Is Employee") {
                val = el.user.data.has_employee === true;
              } else if (el3 === "Is Not Employee") {
                val = el.user.data.has_employee === false;
              } else if (el3 === "has access") {
                val =
                  el.user.data.user.state === "operational" &&
                  el.user.data.is_suspended === false;
              } else if (el3 === "suspended") {
                val = el.user.data.is_suspended === true;
              } else if (el3 === "blocked") {
                val = el.user.data.user.state === "blocked";
              }
              return val;
            });
          });
        }
      }
      resultArray.sort((a, b) => {
        const nameA = a.user.data.user.full_name.toUpperCase();
        const nameB = b.user.data.user.full_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return resultArray;
    },
  },
  watch: {
    filteredUsers(newVal) {
      if (newVal) {
        this.paginationModel.total = newVal.length;
      }
    },
  },
  methods: {
    ...mapActions(["setLoadingStatus"]),
    getUserAccess(state, isSuspended) {
      if (state == "operational" && isSuspended === false) {
        return "has-access";
      } else if (isSuspended === true) {
        return "suspended";
      } else {
        return state;
      }
    },
    formatDate(date) {
      const originalDate = new Date(date);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[originalDate.getMonth()];
      const year = originalDate.getFullYear();
      const day = originalDate.getDate();
      const hours = originalDate.getHours();
      const minutes = originalDate.getMinutes();
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },
    async onCreatedUser(user) {
      console.log(user);
      await this.loadUsers();
    },
    handleTabChange(tabIndex) {
      this.selectedTab = tabIndex;
    },
    resendInviteAction() {
      this.setLoadingStatus(true);
      if (this.currentSelectedRow?.user?.id) {
        const obj = {
          user: this.currentSelectedRow?.user.id,
          account: this.accountID ?? this.currentSelectedRow.account,
        };
        userResendInvite(this.currentSelectedRow.user.id, obj)
          .then(() => {
            //
          })
          .catch((err) => {
            console.error("error", err);
          })
          .finally(() => {
            this.setLoadingStatus(false);
            this.resendInviteDialog = false;
          });
      } else {
        this.setLoadingStatus(false);
        this.resendInviteDialog = false;
      }
    },
    getUserList() {
      this.setLoadingStatus(true);
      getListofUser(this.accountID)
        .then((res) => {
          this.userList = res.data.results;
          this.formateData();
          if (res.data && res.data.count) {
            this.paginationModel.total = res.data.count;
          }
        })
        .catch((error) => {
          this.userList = [];
          console.log("error: ", error);
        })
        .finally(() => {
          this.setLoadingStatus(false);
        });
    },
    async requestData(data) {
      if (data && data.page) {
        this.paginationModel.page = data.page;
      }
    },
    async loadUsers(pageNo = 1) {
      const { data } = await getListofUser(this.accountID, pageNo, 10);
      if (data && data.count) {
        this.paginationModel.total = data.count;
      }
      if (data && data.results) {
        this.userList = data.results;
        this.formateData();
      }
    },
    formateData() {
      this.userList.forEach((element) => {
        element.user.full_name =
          element.user.first_name + " " + element.user.last_name;
        element.accessChip = [];
        if (element.has_access) {
          if (element.is_owner) {
            element.accessChip.push({
              permissionType: "Owner",
              appDropdown: "Owner",
              IsEnabled: true,
            });
          } else if (element.is_co_owner) {
            element.accessChip.push({
              permissionType: "Co-Owner",
              appDropdown: "Co-Owner",
              IsEnabled: true,
            });
          } else {
            this.appAccessTypes.forEach((access) => {
              if (element[access.key]) {
                element.accessChip.push(access);
              }
            });
          }
        }
        element.actionMenu = [];
        if (element.user.state == "invited") {
          element.actionMenu.push({
            name: "Resend Invite",
            value: "resend_invite",
            icon: "icons/dropdown/resend.svg",
            order: 1,
            disabled: false,
          });
        } else if (element.user.state == "draft") {
          element.actionMenu.push({
            name: "Activate",
            value: "activate",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        } else if (element.user.state == "active") {
          element.actionMenu.push({
            name: "Suspend",
            value: "suspend",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        } else if (element.user.state == "suspended") {
          element.actionMenu.push({
            name: "Re-Activate",
            value: "reactivate",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        }
        element.actionMenu.push({
          name: "Change Password",
          value: "change_password",
          icon: "edit_employee.svg",
          order: 1,
          disabled: false,
        });
        if (
          !element.is_owner &&
          !element.is_co_owner &&
          !element.is_suspended
        ) {
          element.actionMenu.push({
            name: "Manage Apps Access",
            value: "manage_apps",
            icon: "edit_employee.svg",
            order: 1,
            disabled: false,
          });
        }
        if (!element.is_owner && !element.is_suspended) {
          element.actionMenu.push({
            name: "Suspend",
            value: "suspend",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
            negative: true,
          });
        }
        if (!element.is_owner && element.is_suspended) {
          element.actionMenu.push({
            name: "Give back access",
            value: "give_back_access",
            icon: "changeStatus.svg",
            order: 1,
            disabled: false,
          });
        }
      });
    },
    handleDropdownClick(data, row) {
      this.currentSelectedRow = this.userList.find(
        (x) => x.id === row.user.data.id
      );
      if (data.value == "resend_invite") {
        this.resendInviteDialog = true;
        this.dialogTitle = false;
        this.dialogTitle1 = false;
      } else if (data.value == "suspend") {
        this.setLoadingStatus(true);
        let obj = {
          email: this.currentSelectedRow.user.email,
          state: "suspended",
        };
        userStateUpdateSuspend(this.accountID, this.currentSelectedRow.id, obj)
          .then(() => {
            this.currentSelectedRow.user.state = "suspended";
            this.formateData();
            this.getUserList();
          })
          .catch((err) => {
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.setLoadingStatus(false);
          });
      } else if (data.value == "give_back_access") {
        this.setLoadingStatus(true);
        let obj = {
          email: this.currentSelectedRow.user.email,
          state: "invited",
        };
        userStateUpdateGiveBack(this.accountID, this.currentSelectedRow.id, obj)
          .then(() => {
            this.currentSelectedRow.user.state = "invited";
            this.formateData();
            this.getUserList();
          })
          .catch((err) => {
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.setLoadingStatus(false);
          });
      } else if (data.value == "change_permissions_action") {
        this.appPermissions = [];
        this.dialogTitle1 = true;
        this.dialogTitle = false;
        this.resendInviteDialog = false;
        this.appAccessTypes.forEach((access) => {
          if (row.user.data[access.key]) {
            this.appPermissions.push(access.appDropdown);
          }
        });
      }
    },
    onChangePassword() {
      if (this.$refs.password.validate()) {
        this.setLoadingStatus(true);
        changePassword(this.currentSelectedRow.user.id, {
          password: this.password,
        })
          .then(() => {
            // this.$refs.password.reset();
          })
          .catch((err) => {
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.$refs.password.reset();
            this.dialogTitle = false;
            this.setLoadingStatus(false);
          });
      }
    },
    onSaveAppAccess() {
      this.setLoadingStatus(true);
      const obj = {
        has_readiness_access: this.appPermissions.includes("Proficiency"),
        has_knowledge_access: this.appPermissions.includes("Knowledge"),
        has_performance_access: this.appPermissions.includes("Performance"),
        has_compensation_access: this.appPermissions.includes("Compensation"),
        has_employee_portal_access:
          this.appPermissions.includes("Employee Portal"),
      };
      manageAppAccess(this.accountID, this.currentSelectedRow.user.id, obj)
        .then(() => {
          this.appAccessTypes.forEach((access) => {
            this.currentSelectedRow[access.key] = this.appPermissions.includes(
              access.appDropdown
            );
          });
          this.formateData();
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$toast.error("something went wrong, please try again.");
        })
        .finally(() => {
          this.dialogTitle1 = false;
          this.setLoadingStatus(false);
        });
    },
    onRemoveAppAccess(access, row) {
      this.currentSelectedRow = this.userList.find(
        (x) => x.id === row.user.data.id
      );
      this.setLoadingStatus(true);
      const obj = {};
      switch (access) {
        case "Proficiency":
          obj.has_readiness_access =
            this.appPermissions.includes("Proficiency");
          break;
        case "Knowledge":
          obj.has_knowledge_access = this.appPermissions.includes("Knowledge");
          break;
        case "Performance":
          obj.has_performance_access =
            this.appPermissions.includes("Performance");
          break;
        case "Compensation":
          obj.has_compensation_access =
            this.appPermissions.includes("Compensation");
          break;
        case "Employee Portal":
          obj.has_employee_portal_access =
            this.appPermissions.includes("Employee Portal");
          break;
        default:
          break;
      }
      manageAppAccess(this.accountID, this.currentSelectedRow.user.id, obj)
        .then(() => {
          this.appAccessTypes.forEach((access) => {
            this.currentSelectedRow[access.key] = this.appPermissions.includes(
              access.appDropdown
            );
          });
          this.loadUsers();
          this.formateData();
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$toast.error("something went wrong, please try again.");
        })
        .finally(() => {
          this.dialogTitle1 = false;
          this.setLoadingStatus(false);
        });
    },
    reset() {
      this.dialogTitle = false;
      this.dialogTitle1 = false;
      this.resendInviteDialog = false;
      this.accountPermission = {
        is_co_owner: true,
        has_access: true,
        has_readiness_access: false,
        has_knowledge_access: false,
        has_performance_access: false,
        has_compensation_access: false,
        has_employee_portal_access: true,
      };
    },
    onFilter(data) {
      this.filterText = data;
    },
    removeFilter(removedFilter) {
      this.clearSelection = removedFilter;
    },
    filteredModalUsers(filters) {
      const newArray = filters
        .map((item) => {
          if (item.value !== null) {
            if (typeof item === "string") {
              return item;
            } else if (
              typeof item === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              item.hasOwnProperty("value")
            ) {
              return item.value;
            }
          }
        })
        .filter(Boolean);
      this.appliedFilters = newArray;
    },
    setUserStatus(user) {
      if (user.state == "") {
        return "has-access";
      } else {
        return "has-access";
      }
    },
  },
  components: {
    ExCreateEmployeeWizard,
    SettingsMenu,
    DataTable,
    UserCard,
    UserPermissionBadge,
    UserStatusBadge,
    DropDownMenu,
    CloseIcon,
    MainButton,
    ExActionsPopUp,
    AppIntegrationsList,
  },
};
</script>
<style scoped>
.with-employee {
  width: 100%;
}
</style>
<style scoped>
.button-next {
  width: 87px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: solid 1px rgba(234, 236, 240, 1);
  background-color: rgba(242, 244, 247, 1);
  color: rgba(152, 162, 179, 1);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.text-black-600 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: rgba(16, 24, 40, 1);
}

.text-black-500 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(52, 64, 84, 1);
}
.v-text-field__slot {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(102, 112, 133, 1);
}
.input {
  border-radius: 8px;
  margin: 0;
}

.pop-up {
  width: 480px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
}
.modal_header {
  z-index: 1;
  position: relative;
}
.modal_content {
  z-index: 1;
  position: relative;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
.font-size-14 {
  font-size: 14px;
}
.text-disabled {
  color: rgba(102, 112, 133, 1);
}

.text-gray-dark {
  color: #666666;
}
.app-selector {
  border: 2px solid rgba(127, 86, 217, 1);
  cursor: pointer;
}
.v-input--selection-controls {
  padding-top: 0;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin: 0px;
}
.v-input--selection-controls__input .v-input__slot .v-label {
  padding-left: 1px !important;
}
.v-input--selection-controls__input .v-input__slot .theme--light .v-label {
  color: black;
  font-size: 14px;
}
</style>

<style>
.pop-up .v-input__slot {
  min-height: 44px !important;
}
.status-label {
  margin-left: 10px;
}
.storybook-status-badge--suspended .custom-bedge.v-badge--dot .v-badge__badge {
  background-color: #b42318 !important;
}
.icon-container {
  display: inline-block;
  padding: 8px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}
.icon-container:hover {
  background-color: #f2edff;
  box-shadow: 0 4px 4px 0px rgba(102, 102, 102, 0.1);
}
</style>
